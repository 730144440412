import { useState } from "react";
import AllCategories from "../../AllCategories/index"; // Assuming you have an AllCategories component
import { Link, useNavigate } from "react-router-dom";

const NavComponent = () => {
  const tabs = [
    { label: "Deals", route: "/deals" },
    { label: "Wholesale Suppliers", route: "/wholesale-suppliers" },
    { label: "Liquidators", route: "/liquidators" },
    { label: "Dropshippers", route: "/dropshippers" },
  ];

  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab.label); // Set active tab
    navigate(tab.route); // Navigate to the corresponding route
  };

  return (
    <nav className="w-full bg-white border-y border-neutral-200 test-AllCategoriesNav">
      <div className="relative w-full hide-scrollbar max-w-[1440px] mx-auto phone:overflow-x-scroll phone:px-[24px] px-[56px] flex items-center">
        <div className="relative phone:hidden tablet:hidden">
          <AllCategories />
          {/* Side Navigation Desktop */}
        </div>
        <div className="flex items-center w-[800px]">
          <button
            id="toggle-button-list-category-tablet"
            className="hidden tablet:block"
          >
            <img src="../assets/images/v1/icons/menu.svg" alt="menu" />
          </button>
          {tabs.map((tab) => (
            <Link
              key={tab.label}
              className={`py-[12px] min-w-fit px-[24px] text-[14px] leading-[21px] font-normal border-b-2 ${
                activeTab === tab.label
                  ? "text-primary-main border-primary-main"
                  : "text-neutral-500 border-b-transparent"
              } hover:border-primary-main`}
              onClick={() => handleTabClick(tab)}
              to={tab.route}
            >
              {tab.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default NavComponent;
