import React from "react";

const RightsFooter = () => {
  return (
    <div className="w-full bg-[#1F1F1F] border-t-[1.2px] border-[#040404]">
      <div className="w-full max-w-[1440px] mx-auto phone:py-[32px] phone:px-[24px] phone:gap-6 tablet:py-[32px] tablet:px-[40px] px-[80px] py-[32px] flex phone:flex-col phone:items-start tablet:flex-row tablet:items-end tablet:gap-[48px] items-center justify-between">
        <div className="flex flex-col w-full gap-14 desktop:items-center desktop:flex-row">
          <div className="phone:w-full grid gap-[8px]">
            <div className="phone:w-full flex phone:items-start items-center gap-[8px]">
              <img
                src="../assets/images/v1/icons/copy.svg"
                className="w-[16px] h-[16px]"
                alt="copyright"
              />
              <p className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal">
                2004 - 2023 WholesaleDeals.co.uk, All Rights Reserved
              </p>
            </div>
            <div className="hidden phone:w-full flex phone:items-start items-center gap-[8px]">
              <img
                src="../assets/images/v1/icons/building.svg"
                className="w-[16px] h-[16px]"
                alt="building"
              />
              <p className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal">
                Tradegenius SL, Calle Villalba Hervas 2, 4-2 Santa Cruz de
                Tenerife, 38002 - Spain
              </p>
            </div>
          </div>
          <div className="w-fit phone:w-full phone:justify-between flex items-center gap-[40px]">
            <a
              href="#"
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Terms
            </a>
            <a
              href="#"
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Cookies
            </a>
            <a
              href="#"
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="flex phone:flex-col phone:w-full items-center gap-[56px] phone:gap-[24px] tablet:justify-between tablet:w-fit">
          <a href="/v1/index.html" className="no-underline">
            <img
              src="../assets/images/v1/icons/wholesale-footer.svg"
              className="w-[228px] h-[34px]"
              alt="wholesale-footer"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RightsFooter;
