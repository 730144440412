import React, { useEffect } from "react";
import FilterDropdown from "./FilterDropdown";
import CountryFilterDropdown from "../CountryFilterDropdown";
import { useSelector } from "react-redux";
import GradeDropDown from "../GradeDropDown";

const Filter = () => {
  const countries = useSelector((state) => state.country.data);
  const gradeOptions = [
    { label: "New", value: "new" },
    { label: "Used", value: "used" },
    { label: "Mix / Return", value: "mix-return" },
    { label: "Outlet", value: "outlet" },
    { label: "Refurbished", value: "refurbished" },
    { label: "Damaged", value: "damaged" },
  ];
  // console.log("countries", countries);
  useEffect(() => {}, [countries]);
  return (
    <div className="w-full filter-panel phone:hidden tablet:hidden grid rounded-[16px] bg-white">
      <div className="w-full border-b border-neutral-200 py-[16px] px-[20px]">
        <h1 className="font-bold text-[18px] leading-[27px] text-neutral-900">
          Filter
        </h1>
      </div>
      <FilterDropdown />
      <CountryFilterDropdown countries={countries} />
      {/* Grade */}
      <GradeDropDown title={"Grade"} options={gradeOptions} />
    </div>
  );
};

export default Filter;
