import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETCOMMONDEALS } from "../api";
import logout from "../../shared/logout";

export const getCommonDeals = createAsyncThunk(
  "/getCommonDeals",
  async (data, { rejectWithValue }) => {
    try {
      console.log("Fetching deals with data: ", data); // Debugging line

      // Prepare query parameters for search, searchMode, and sortBy
      const params = new URLSearchParams();

      if (data.dealId) params.append("page", data.dealId);
      if (data.search) params.append("search", data.search);
      if (data.searchMode) params.append("searchMode", data.searchMode);
      if (data.sortBy) params.append("sortBy", data.sortBy);

      const queryString = params.toString() ? `?${params.toString()}` : "";

      // Fetch deals based on the dealId and other parameters
      const response = await axios.get(`${GETCOMMONDEALS}${queryString}`);

      // console.log("Response: ", response.data); // Debugging line
      return response.data; // Ensure this is the expected format
    } catch (error) {
      console.error("Error fetching deals: ", error); // Debugging line
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);

const initialState = {
  data: {},
  isLoading: true,
  currentPage: 1,
  itemsPerPage: 10,
  totalPage: 0,
  error: null,
};

const commonDealsSlice = createSlice({
  name: "common_deals",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
      console.log("action.payload", action.payload);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommonDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCommonDeals.fulfilled, (state, action) => {
        // console.log("API call succeeded:", action.payload);
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCommonDeals.rejected, (state) => {
        console.log("API call failed:");
        state.isLoading = false;
      });
  },
});

export const { setPage, setError } = commonDealsSlice.actions;
export default commonDealsSlice.reducer;
