import { useEffect, FC, Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to get the current route
import { useAuth, useRedirection } from '../hooks';

const UnAuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);
  const location = useLocation(); // Get the current route

  const { getUserInfo, logout } = useAuth(),
    { redirectUserDashboard } = useRedirection();

  useEffect(() => {
    checkCookie();
  }, [location.pathname]); // Dependency on location.pathname to react to route changes

  const checkCookie = () => {
    try {
      // Define a list of routes to bypass authentication checks
      const bypassRoutes = ['/reset-password','/userlogin','/deals'];

      const currentPath = location.pathname;

      // Check if the current route should bypass authentication checks
      if (bypassRoutes.some(route => isRouteMatch(route, currentPath))) {
        setStatus(true); // Allow access without further checks
        return;
      }

      const userInfo = getUserInfo();
      if (!userInfo?.userId) {
        logout();
      } else {
        redirectUserDashboard();
      }
      setStatus(true);
    } catch (error) {
      logout();
    }
  };

  // Utility function to check if the path matches the route pattern
  const isRouteMatch = (pattern, path) => {
    // Normalize both paths to ensure consistent matching
    const normalizedPattern = pattern.endsWith('/') ? pattern.slice(0, -1) : pattern;
    const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
    
    // Check if the normalized path starts with the normalized pattern
    return normalizedPath.startsWith(normalizedPattern);
  };

  return status ? <Fragment>{component}</Fragment> : <></>;
};

export default UnAuthGuard;
