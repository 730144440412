import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  RegisterUser,
  checkUserName,
} from "../../../../store/slices/authSlice"; // Assuming a registerUser action exists
import Spinner from "../../../../assets/spinner/Spinner"; // Import your Spinner component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { eyeClose, eyeOpen } from "../../../../assets/images/v1/single";
import { debounce } from "lodash";

// Validation Schema
const validationSchema = Yup.object({
  username: Yup.string().required("Username is Required"),
  gender: Yup.string()
    .oneOf(["male", "female"], "Please choose salutation")
    .required("Please Choose Salutation"),
  firstName: Yup.string().required("First Name is Required"),
  lastName: Yup.string().required("Last Name is Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is Required")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      "Password should be at least 8 characters, include at least one letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required"),
  agree: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
});

const RegisterForm = ({ goToLoginModal }) => {
  const dispatch = useDispatch();
  // configuring states for password and CPassword to make that togglable
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [passwordEye, setPasswordEye] = useState(eyeOpen);
  const [cPasswordEye, setCPasswordEye] = useState(eyeOpen);
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loadingField, setLoadingField] = useState("");
  const handleChange = async (field, value) => {
  if(value!=""){
    setLoadingField(field); // Show loader for the current field
    try {
      const response = await dispatch(
        checkUserName({ [field]: value })
      ).unwrap();
      if (response.data.isAvailable === false) {
        if (field === "username") {
          setUsernameError("");
          setUsernameError("Username is already taken");
        } else if (field === "email") {
          setEmailError("");
          setEmailError("Email is already registered");
        }
      } else {
        // Clear error if available
        if (field === "username") {
          setUsernameError("");
          setUsernameError("User Name is Available");
        } else if (field === "email") {
          setEmailError("");
          setEmailError("Email is Available");
        }
      }
    } catch (error) {
      console.error(`Error checking ${field}:`, error);
    } finally {
      setLoadingField(""); // Hide loader after the check is complete
    }
  }
  else if(value==""){
    setUsernameError("");
    setEmailError("");
  }
}
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    try {
      const { username, email, confirmPassword, firstName, lastName, gender } =
        values;
      const genderofperson = gender === "male" ? "M" : "F";
      const newObj = {
        username,
        email,
        password: confirmPassword,
        firstName,
        lastName,
        gender: genderofperson,
      };

      // Show spinner or disable the submit button immediately
      setSubmitting(true);

      // Dispatch the RegisterUser action (assuming registerUser is properly set up in Redux)
      const response = await dispatch(RegisterUser(newObj));

      // Handle success
      if (
        response.type === "register/fulfilled" &&
        response.payload.message === "User registered successfully"
      ) {
        // Set registration success flag to true
        setIsRegisterSuccess(true);

        // Redirect to login modal and show toast after a delay
        goToLoginModal(); // Assuming this handles navigation
        toast.success("User registered successfully");

        // Reset the form after successful registration
        resetForm();
      }
      // Handle rejection (errors from the API)
      else if (response.type === "register/rejected") {
        setFieldError(
          "general",
          response.payload || "Register failed. Please try again."
        );
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setFieldError("general", "An error occurred. Please try again.");
      toast.error("Registration failed");
    } finally {
      // Stop spinner/enable the button again in both success and error scenarios
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        username: "",
        gender: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        agree: false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, setFieldValue }) =>
        isSubmitting || isRegisterSuccess ? (
          <div className="flex justify-center items-center h-screen">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-3 phone:gap-4">
              <div className="flex items-center gap-2">
                <svg
                  width={24}
                  height={25}
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.12"
                    d="M3 18.8257C3 18.3365 3 18.0919 3.05526 17.8618C3.10425 17.6577 3.18506 17.4626 3.29472 17.2837C3.4184 17.0818 3.59136 16.9089 3.93726 16.563L16.5 4.00023C17.3285 3.1718 18.6716 3.1718 19.5 4.00023C20.3285 4.82865 20.3285 6.1718 19.5 7.00023L6.93729 19.563C6.59138 19.9089 6.41843 20.0818 6.2166 20.2055C6.03765 20.3152 5.84256 20.396 5.63849 20.445C5.40832 20.5002 5.16372 20.5002 4.67454 20.5002H3V18.8257Z"
                    fill="#E66927"
                  />
                  <path
                    d="M12 20.5002H21M3 20.5002H4.67454C5.16372 20.5002 5.40832 20.5002 5.63849 20.445C5.84256 20.396 6.03765 20.3152 6.2166 20.2055C6.41843 20.0818 6.59138 19.9089 6.93729 19.563L19.5 7.00023C20.3285 6.1718 20.3285 4.82865 19.5 4.00023C18.6716 3.1718 17.3285 3.1718 16.5 4.00023L3.93726 16.563C3.59136 16.9089 3.4184 17.0818 3.29472 17.2837C3.18506 17.4626 3.10425 17.6577 3.05526 17.8618C3 18.0919 3 18.3365 3 18.8257V20.5002Z"
                    stroke="#E66927"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                  Register Free
                </h5>
              </div>
              <p className="text-sm leading-[21px] text-neutral-500">
                Sign up today and join thousands of other Wholesale Deals users
                completely free. Our weekly e-news publication, packed with
                trade news, secrets and offers comes free with every
                registration.
              </p>
            </div>
            <Form id="registerForm" className="mt-6">
              {/* Username */}
              <div className="flex flex-col gap-2 mb-4">
                <label
                  required
                  htmlFor="username"
                  className="text-sm font-medium flex items-center gap-1"
                >
                  Choose Your Username{" "}
                  <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                </label>
                <Field
                  type="text"
                  name="username"
                  placeholder="Enter username..."
                  className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                  onChange={(e) => {
                    handleChange("username", e.target.value);
                    setFieldValue("username", e.target.value);
                  }}
                />
                {loadingField === "username" && (
                  <div className="absolute right-4 top-3">
                    <Spinner size="small" />
                  </div>
                )}
                {usernameError && (
                  <div className="text-red-600 text-sm">{usernameError}</div>
                )}
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </div>

              {/* Gender */}
              <div className="flex items-center gap-1">
                <label
                  required
                  htmlFor="gender"
                  className="text-sm font-medium flex items-center gap-1"
                >
                  Salutation{" "}
                  <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                </label>
                <br />
              </div>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                <div
                  className="flex items-center gap-2 border border-neutral-200 w-full sm:w-[17rem] py-3 px-4 rounded cursor-pointer"
                  onClick={() => document.getElementById("genderMale").click()}
                >
                  <Field
                    id="genderMale"
                    type="radio"
                    name="gender"
                    value="male"
                    className="accent-primary-main cursor-pointer"
                  />
                  <label htmlFor="genderMale">Mr.</label>
                </div>

                <div
                  className="flex items-center gap-2 border border-neutral-200 w-full sm:w-[17rem] py-3 px-4 rounded cursor-pointer"
                  onClick={() =>
                    document.getElementById("genderFemale").click()
                  }
                >
                  <Field
                    id="genderFemale"
                    type="radio"
                    name="gender"
                    value="female"
                    className="accent-primary-main cursor-pointer"
                  />
                  <label htmlFor="genderFemale">Ms.</label>
                </div>
              </div>

              <ErrorMessage
                name="gender"
                component="div"
                className="text-red-600 text-sm mt-2 mb-4"
              />

              {/* Full Name */}
              <div
                className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4"
                style={{ marginTop: "1rem" }}
              >
                <div className="flex flex-col gap-2 mb-4">
                  <label
                    required
                    htmlFor="firstName"
                    className="text-sm font-medium flex items-center gap-1"
                  >
                    First Name{" "}
                    <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="Enter first name..."
                    className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
                <div className="flex flex-col gap-2 mb-4">
                  <label
                    required
                    htmlFor="lastName"
                    className="text-sm font-medium flex items-center gap-1"
                  >
                    Last Name{" "}
                    <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                  </label>
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="Enter Last name..."
                    className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
              </div>

              {/* Email */}
              <div className="flex flex-col gap-2 mb-4">
                <label
                  required
                  htmlFor="email"
                  className="text-sm font-medium flex items-center gap-1"
                >
                  Email{" "}
                  <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                </label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter email address..."
                  className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                  onChange={(e) => {
                    handleChange("email", e.target.value);
                    setFieldValue("email", e.target.value);
                  }}
                />
                {loadingField === "email" && (
                  <div className="absolute right-4 top-3">
                    <Spinner size="small" />
                  </div>
                )}
                {emailError && (
                  <div className="text-red-600 text-sm">{emailError}</div>
                )}
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </div>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/* Password */}
                <div className="flex flex-col gap-2 mb-4">
                  <label
                    required
                    htmlFor="password"
                    className="text-sm font-medium flex items-center gap-1"
                  >
                    Choose Your Password{" "}
                    <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                  </label>
                  <div className="relative">
                    <Field
                      type={passwordEye === eyeOpen ? "password" : "text"}
                      name="password"
                      placeholder="Enter password..."
                      className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                    />
                    <img
                      src={passwordEye}
                      alt=""
                      className="absolute top-3 right-3"
                      onClick={() =>
                        passwordEye === eyeOpen
                          ? setPasswordEye(eyeClose)
                          : setPasswordEye(eyeOpen)
                      }
                    />
                  </div>

                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>

                {/* Confirm Password */}
                <div className="flex flex-col gap-2 mb-4">
                  <label
                    required
                    htmlFor="confirmPassword"
                    className="text-sm font-medium flex items-center gap-1"
                  >
                    Confirm Password{" "}
                    <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                  </label>
                  <div className="relative">
                    <Field
                      type={cPasswordEye === eyeOpen ? "password" : "text"}
                      name="confirmPassword"
                      placeholder="Re-enter password..."
                      className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                    />
                    <img
                      src={cPasswordEye}
                      alt=""
                      className="absolute top-3 right-3"
                      onClick={() =>
                        cPasswordEye === eyeOpen
                          ? setCPasswordEye(eyeClose)
                          : setCPasswordEye(eyeOpen)
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
              </div>

              {/* Terms Agreement */}
              <div className="flex items-center gap-2 mb-6">
                <Field
                  id="termsAgreement"
                  type="checkbox"
                  name="agree"
                  className="w- accent-primary-main border border-[#CBD5E1] cursor-pointer"
                />
                <label
                  htmlFor="agree"
                  className="text-sm font-medium"
                  onClick={() =>
                    document.getElementById("termsAgreement").click()
                  }
                >
                  I have read and agree to the Wholesale Deals Terms
                </label>
              </div>
              <ErrorMessage
                name="agree"
                component="div"
                className="text-red-600 text-sm mt-[-1.1rem] mb-4"
              />
              {/* Error Message */}
              {errors.general && (
                <div className="text-red-600 text-sm mb-4">
                  {errors.general}
                </div>
              )}

              {/* Submit Button */}
              <div className="flex flex-col gap-4 justify-center items-center">
                <button
                  type="submit"
                  className="py-3 flex justify-center items-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner /> : "SIGN UP"}
                </button>
              </div>
            </Form>
          </>
        )
      }
    </Formik>
  );
};

export default RegisterForm;
