// src/components/DropdownMenu.js
import React, { useState } from 'react';
import chevronDown from "../../../assets/images/v1/icons/chevron-down.svg"; // Update the path as needed
import chevronUp from "../../../assets/icons/chevron-up.png";// Ensure you have this image for rotation

const BuyerDropDown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className="relative select-dropdown-container">
      <button
        className="forbuyer px-[6px] py-[3px] rounded-[6px] transition-all duration-300 flex items-center gap-[6px]"
        onClick={toggleDropdown}
      >
        <p className="font-normal text-[14px] leading-[21px] text-neutral-800">
          For buyers
        </p>
        <img
          src={isOpen ? chevronUp : chevronDown} // Rotate arrow based on state
          className={`tablet:w-[16px] tablet:h-[16px] transition-transform duration-300 ${isOpen ? 'rotate-120' : ''} text-black`}
          width="16px"
          height="16px"
          alt="Chevron"
        />
      </button>
      <div className={`w-[136px] ${isOpen ? 'block' : 'hidden'} flex flex-col absolute mt-[7px] left-0 p-[4px] bg-white forbuyer-modal select-dropdown`}>
        <button className="buyer-benefit transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 hover:bg-[#FAF1DC]">
          Buyer Benefits
        </button>
        <button className="buyer-register transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 hover:bg-[#FAF1DC]">
          Register
        </button>
      </div>
    </div>
  );
};

export default BuyerDropDown
