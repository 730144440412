import React from "react";

const DealDetails = () => {
  return (
    <div className="w-full bg-white">
      <div className="w-full max-w-[1440px] mx-auto py-[40px] px-[56px] phone:px-[24px]">
        <div className="w-full bg-neutral-50 rounded-[16px] p-[32px] grid gap-[24px] phone:max-h-[304px] max-h-[280px] overflow-auto phone:p-[20px] phone:gap-[16px]">
          <h3 className="text-[#000] font-bold text-[24px] leading-[33.6px] phone:text-[20px] phone:leading-[27px]">
            Sports, hobbies &amp; leisure
          </h3>
          <div className="grid gap-[24px]">
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              The sports industry is developing dynamically; the fashion of
              being fit is a constantly growing trend. Why not make use of the
              demand for the accessories related to selected sports in order to
              make a profit?
            </p>
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              Are you running an e-shop, outlet store or online wholesale store
              that offers products related to some sport activity? Offer your
              customers new types of products at a better price than other
              companies in your industry. How? Just order regular stocks of
              sports and leisure accessories. This way you can buy quality goods
              for a fraction of their market value, and then sell them at a
              profit. It's simpler than you think, and our platform allows you
              to make risk-free transactions.
            </p>
          </div>
          <h3 className="text-[#000] hidden desktop:block font-bold text-[24px] leading-[33.6px]">
            Wholesale sporting goods - variety of offers
          </h3>
          <div className="w-full grid gap-[16px]">
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              Pallets and containers of sports goods offered on our portal by
              exhibitors include a variety of products. These are often very
              common and fashionable commodities that can be easily
              redistributed both wholesale and directly to the end customer.
            </p>
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              The stock lots include beach accessories, hiking gear, outdoor
              sports accessories, as well as professional equipment suitable for
              gyms, fitness clubs and other facilities. The goods here can also
              be suitable for the whole teams and sports clubs.
            </p>
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              The detailed offer can be divided into thematic categories such
              as:
            </p>
            <ul className="grid gap-[4px]" style={{ listStyleType: "disc" }}>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Beach &amp; pool products – e.g. mats and beach tents, beach
                balls
              </li>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Sports accessories – e.g. headgear, gloves, pads, balls,
                football gates, and many other goods adapted to the needs of
                sports enthusiasts and professional teams
              </li>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Erotic products – perfect for redistribution to sex shops and
                similar stores
              </li>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Sport shoes – both universal footwear and shoes adapted to
                specific disciplines, e.g. running shoes, football boots
              </li>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Sportswear - women, men, and children's sports apparel from
                famous brands as well as less known manufacturers
              </li>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Sports equipment - suitable for both amateurs and sports clubs.
                You can also order pallets and mix containers, where you will
                find an assortment of different products.
              </li>
            </ul>
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              You can also order pallets and mix containers, where you will find
              an assortment of different products. They are usually offered at
              very low prices; you will get even better financial results when
              you redistribute them.
            </p>
          </div>

          <h3 className="text-[#000] hidden desktop:block font-bold text-[24px] leading-[33.6px]">
            Wholesale sports gear suppliers - competitive prices
          </h3>
          <div className="w-full grid gap-[16px]">
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              Attractive prices are the effect of having bought the goods in
              wholesale quantities. This way, the exhibitor is able to offer you
              favorable conditions of cooperation.
            </p>
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              The products' source is also a factor influencing the price. The
              stocklots can come from:
            </p>
            <ul className="grid gap-[4px]" style={{ listStyleType: "disc" }}>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                End of series and surplus production - these products are
                usually fully operational, but the manufacturer discards them
                for a fraction of their value to gain storage space
              </li>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Ex demo products - they may have slight traces of use
              </li>
              <li className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
                Business dissolution
              </li>
            </ul>
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              You can also order used or damaged products. <br />
              Enjoy a wide range of opportunities and increase your
              competitiveness on the market!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealDetails;
