import React from "react";
import Filter from "./Filter";
import SubscribeCard from "../SubscribeCard/SubscribeCard";

const FilterCtaWrapper = () => {
  return (
    <>
      <Filter />
      <SubscribeCard classNames={"bg-primary-main"} />
      <SubscribeCard classNames={"bg-additional-blue"} />
    </>
  );
};

export default FilterCtaWrapper;
