import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGIN, REGISTER, CHECK_USERNAME } from "../api";
import { createCookie, deleteCookie } from "../../hooks/cookies";

// Thunks
export const LoginUser = createAsyncThunk(
  "login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(LOGIN, credentials);
      return response.data;
    } catch (error) {
      // Check if it's an Axios error
      if (error.response && error.response.data) {
        // Return a specific error message from the response
        return rejectWithValue(error.response.data.message || "Login failed");
      }
      return rejectWithValue(error.message || "Network Error");
    }
  }
);

export const RegisterUser = createAsyncThunk(
  "register",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(REGISTER, credentials);
      return response.data;
    } catch (error) {
      // Handle the error in a similar way for register
      if (error.response && error.response.data) {
        return rejectWithValue(
          error.response.data.message || "Registration failed"
        );
      }
      return rejectWithValue(error.message || "Network Error");
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await axios.post("/api/auth/logout");
});

export const checkUserName = createAsyncThunk("/checkUserName", async (username,{ rejectWithValue }) => {
  try {
  const response = await axios.post(CHECK_USERNAME,username);
  return response.data;
  }
  catch (error) {
    // Handle the error in a similar way for register
    if (error.response && error.response.data) {
      return rejectWithValue(
        error.response.data.message
      );
    }
    return rejectWithValue(error.message || "Network Error");
  }
});

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    message: "",
    isLoading: true,
    error: null,
  },
  reducers: {
    clearAuth: (state) => {
      state.user = null;
      deleteCookie(); // Remove cookies
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.message = "";
        state.isLoading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
        createCookie(
          action.payload.data.token,
          action.payload.data.remember_me
        ); // Set cookies
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message; // Use the message from rejectWithValue or a fallback
      })
      .addCase(RegisterUser.pending, (state) => {
        state.message = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(RegisterUser.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(RegisterUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(checkUserName.pending, (state) => {
        state.message = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkUserName.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(checkUserName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { clearAuth } = authSlice.actions;

export default authSlice.reducer;
