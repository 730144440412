import React from "react";
import LoginHeader from "../../../pages/Login/LoginHeader/LoginHeader";
import { close } from "../../../../assets/images/v1/homepage";
const MainNav = ({ ShowForgetPassword, ShowRegisterForm }) => {
  return (
    <div>
      {" "}
      <div className="w-full bg-white border-b border-neutral-200">
        <div
          id="filterpanel-tablet"
          className="w-full hidden desktop:hidden h-screen bg-[#000]/40 fixed z-30 top-0 left-0 overflow-scroll h-full"
        >
          <div className="hidden tablet:block w-full bg-white absolute z-50 top-[135px]">
            <div className="w-full px-[56px] py-[16px] flex items-center justify-between">
              <h3 className="font-bold text-[18px] leading-[27px] text-neutral-900">
                Filter
              </h3>
              <button className="close-filterpanel">
                <img src={close} className="w-[32px] h-[32px]" />
              </button>
            </div>
            <div className="w-full pb-[20px] grid grid-cols-3 gap-[40px] px-[56px]">
              <div className="w-full">
                <h1 className="font-bold text-[16px] py-[16px] leading-[24px] text-left text-neutral-900">
                  Sports, hobbies &amp; leisure - Category
                </h1>
                <div className="w-full">
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Stock lot shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Baby's Shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Children's Shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Woman's Shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Men's Shoes
                  </button>
                </div>
              </div>
              <div className="w-full">
                <h1 className="font-bold text-[16px] py-[16px] leading-[24px] text-left text-neutral-900">
                  Grade
                </h1>
                <div className="w-full">
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      New
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Used
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Mix / Return
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Outlet
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Refurbished
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Damaged
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <h1 className="font-bold text-[16px] py-[16px] leading-[24px] text-left text-neutral-900">
                  All Countries
                </h1>
                <div className="w-full">
                  <input
                    type="text"
                    className="w-full py-[12px] px-[16px] rounded-[10px] min-h-[48px] mb-[8px] border border-neutral-200 text-[16px] leading-[24px] font-normal text-neutral-400"
                    placeholder="Search Countries"
                    name=""
                    id=""
                  />
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/polan.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Poland
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/germany.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Germany
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/italy.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Italy
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/spain.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Spain
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/france.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        France
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/netherland.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Netherlands
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/belgium.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Belgium
                      </p>
                    </div>
                  </div>
                  <button className="text-primary-main text-[16px] leading-[24px] font-normal py-[12px] w-full text-left">
                    See More
                  </button>
                </div>
              </div>
            </div>
            {/* Sport Category */}
            <div className="w-full py-[20px]">
              <div className="w-full px-[20px] py-[16px]">
                <h3 className="font-bold text-[18px] leading-[27px] text-neutral-900">
                  Sports, hobbies &amp; leisure - Category
                </h3>
                <p className="py-[12px] text-neutral-600 font-normal text-[16px] leading-[24px]">
                  Wholesale stocks of sports and leisure equipment, as well as
                  musical instruments and other hobbies-related goods. The
                  category includes brand new wholesale goods, ex demo and ex
                  lease products, and untested customer returns for many
                  different sports, like football, winter sports, swimming
                  equipment, basketball and many other. You can find here
                  products of many popular sports brands like Nike, Adidas or
                  Puma, but also unbranded sports equipment. All of the offers
                  are coming directly from wholesalers, manufacturers and
                  distributors of sports, hobbies and leisure products from all
                  over the world.
                </p>
              </div>
            </div>
            {/* Subscribe */}
            <div className="w-full pt-[20px] pb-[40px] px-[20px] grid grid-cols-2 gap-[24px]">
              <div className="w-full bg-primary-main rounded-[16px] p-[24px] grid gap-[24px]">
                <img
                  src="../assets/images/v1/deals/subscribe.svg"
                  className="w-[80px] h-[68px] mx-auto"
                  alt="subscribe-icon"
                />
                <div className="">
                  <h3 className="font-semibold text-[18px] leading-[27px] text-white text-center mb-[8px]">
                    Subscribe to get all access
                  </h3>
                  <p className="text-white opacity-80 text-[14px] leading-[21px] text-center">
                    Now you can buy wholesale and dropship products at 45% to
                    95% margins, safe in the knowledge they are already selling
                    successfully.
                  </p>
                </div>
                <button className="text-primary-main bg-white rounded-[8px] text-[14px] leading-[21px] font-semibold w-[160px] h-[40px] text-center mx-auto hover:bg-neutral-gray transition-all duration-200 hover:bg-neutral-gray transition-all duration-200">
                  Subscribe
                </button>
              </div>
              <div className="w-full bg-additional-blue rounded-[16px] p-[24px] grid gap-[24px]">
                <img
                  src="../assets/images/v1/deals/premium.svg"
                  className="w-[80px] h-[68px] mx-auto"
                  alt="subscribe-icon"
                />
                <div className="">
                  <h3 className="font-semibold text-[18px] leading-[27px] text-white text-center mb-[8px]">
                    Are you ready to premium membership?
                  </h3>
                  <p className="text-white opacity-80 text-[14px] leading-[21px] text-center">
                    Now you can buy wholesale and dropship products at 45% to
                    95% margins, safe in the knowledge they are already selling
                    successfully.
                  </p>
                </div>
                <button className="text-additional-blue bg-white rounded-[8px] text-[14px] leading-[21px] font-semibold w-[224px] h-[40px] text-center mx-auto hover:bg-neutral-gray transition-all duration-200 hover:bg-neutral-gray transition-all duration-200">
                  Get Premium Membership
                </button>
              </div>
            </div>
          </div>
          <div className="hidden w-full bg-white phone:block">
            {/* Header */}
            <div className="w-full px-[24px] py-[32px] flex items-center justify-between">
              <h3 className="font-bold text-[18px] leading-[27px] text-neutral-900">
                Filter
              </h3>
              <button className="close-filterpanel">
                <img src={close} className="w-[32px] h-[32px]" />
              </button>
            </div>
            <div className="w-full filter-panel  grid rounded-[16px] bg-white">
              <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
                <button className="w-full py-[16px] flex items-center justify-between">
                  <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
                    Sports, hobbies &amp; leisure - Category
                  </h1>
                  <img
                    data-icon="arrow"
                    src="../assets/images/v1/deals/down.svg"
                    className="w-[24px] h-[24px]"
                  />
                </button>
                <div className="w-full filter-dropdown-container">
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Stock lot shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Baby's Shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Children's Shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Woman's Shoes
                  </button>
                  <button className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
                    Men's Shoes
                  </button>
                </div>
              </div>
              <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
                <button className="w-full py-[16px] flex items-center justify-between">
                  <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
                    All Countries
                  </h1>
                  <img
                    data-icon="arrow"
                    src="../assets/images/v1/deals/down.svg"
                    className="w-[24px] h-[24px]"
                  />
                </button>
                <div className="w-full filter-dropdown-container">
                  <input
                    type="text"
                    className="w-full py-[12px] px-[16px] rounded-[10px] min-h-[48px] mb-[8px] border border-neutral-200 text-[16px] leading-[24px] font-normal text-neutral-400"
                    placeholder="Search Countries"
                    name=""
                    id=""
                  />
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/polan.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Poland
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/germany.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Germany
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/italy.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Italy
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/spain.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Spain
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/france.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        France
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/netherland.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Netherlands
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <div className="flex items-center gap-[8px]">
                      <img src="../assets/images/v1/deals/country/belgium.svg" />
                      <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                        Belgium
                      </p>
                    </div>
                  </div>
                  <button className="text-primary-main text-[16px] leading-[24px] font-normal py-[12px] w-full text-left">
                    See More
                  </button>
                </div>
              </div>
              {/* Grade */}
              <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
                <button className="w-full py-[16px] flex items-center justify-between">
                  <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
                    Grade
                  </h1>
                  <img
                    data-icon="arrow"
                    src="../assets/images/v1/deals/down.svg"
                    className="w-[24px] h-[24px]"
                  />
                </button>
                <div className="w-full filter-dropdown-container">
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      New
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Used
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Mix / Return
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Outlet
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Refurbished
                    </p>
                  </div>
                  <div className="w-full flex items-center gap-[12px] py-[12px]">
                    <input
                      type="checkbox"
                      className="w-[20px] accent-primary-main h-[20px]"
                      name=""
                      id=""
                    />
                    <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                      Damaged
                    </p>
                  </div>
                </div>
              </div>
              {/* Leisure Categories */}
              <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
                <button className="w-full py-[16px] flex items-center justify-between">
                  <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
                    Sports, hobbies &amp; leisure - Category
                  </h1>
                  <img
                    data-icon="arrow"
                    src="../assets/images/v1/deals/down.svg"
                    className="w-[24px] h-[24px]"
                  />
                </button>
                <p className="filter-dropdown-container my-[12px] text-neutral-600 text-[16px] leading-[24px] font-normal">
                  Wholesale stocks of sports and leisure equipment, as well as
                  musical instruments and other hobbies-related goods. The
                  category includes brand new wholesale goods, ex demo and ex
                  lease products, and untested customer returns for many
                  different sports, like football, winter sports, swimming
                  equipment, basketball and many other. You can find here
                  products of many popular sports brands like Nike, Adidas or
                  Puma, but also unbranded sports equipment. All of the offers
                  are coming directly from wholesalers, manufacturers and
                  distributors of sports, hobbies and leisure products from all
                  over the world.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-[16px] p-5">
              <div className="w-full bg-primary-main rounded-[16px] p-[24px] grid gap-[24px]">
                <img
                  src="../assets/images/v1/deals/subscribe.svg"
                  className="w-[80px] h-[68px] mx-auto"
                  alt="subscribe-icon"
                />
                <div className="">
                  <h3 className="font-semibold text-[18px] leading-[27px] text-white text-center mb-[4px]">
                    Subscribe to get all access
                  </h3>
                  <p className="text-white opacity-80 text-[14px] leading-[21px] text-center max-w-[266px] tracking-[0.3px] mx-auto">
                    Now you can buy wholesale and dropship products at 45% to
                    95% margins, safe in the knowledge they are already selling
                    successfully.
                  </p>
                </div>
                <button className="text-primary-main bg-white rounded-[8px] text-[14px] leading-[21px] font-semibold w-[160px] h-[40px] text-center mx-auto hover:bg-neutral-gray transition-all duration-200">
                  Subscribe
                </button>
              </div>
              <div className="w-full bg-additional-blue rounded-[16px] p-[24px] grid gap-[24px]">
                <img
                  src="../assets/images/v1/deals/premium.svg"
                  className="w-[80px] h-[68px] mx-auto"
                  alt="subscribe-icon"
                />
                <div className="">
                  <h3 className="font-semibold text-[18px] leading-[27px] text-white text-center mb-[4px]">
                    Are you ready to premium membership?
                  </h3>
                  <p className="text-white opacity-80 text-[14px] leading-[21px] text-center max-w-[266px] tracking-[0.3px] mx-auto">
                    Now you can buy wholesale and dropship products at 45% to
                    95% margins, safe in the knowledge they are already selling
                    successfully.
                  </p>
                </div>
                <button className="text-additional-blue bg-white rounded-[8px] text-[14px] leading-[21px] font-semibold w-[224px] h-[40px] text-center mx-auto hover:bg-neutral-gray transition-all duration-200">
                  Get Premium Membership
                </button>
              </div>
            </div>
          </div>
        </div>
        <LoginHeader
          setForgotPass={ShowForgetPassword}
          ShowRegisterForm={ShowRegisterForm}
        />
      </div>
    </div>
  );
};

export default MainNav;
