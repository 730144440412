import { useState } from "react";
import ShowFlag from "../../../../sharedComponent/Flag/ShowFlag";
import chevronDown from "../../../../../assets/images/v1/icons/chevron-down.svg";
const CountryFilterDropdown = ({ countries }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCountries, setVisibleCountries] = useState(5); // Number of countries to show initially

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const showMoreCountries = () => {
    setVisibleCountries((prevCount) => prevCount + 5); // Show 5 more countries each time
  };

  const filteredCountries = countries.filter((country) =>
    country.fldCountryName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const countriesToDisplay = filteredCountries.slice(0, visibleCountries); // Limit the number of countries displayed

  return (
    <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
      <button
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          All Countries
        </h1>
        <img
          data-icon="arrow"
          src={chevronDown}
          className={`w-[24px] h-[24px] transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          alt="dropdown arrow"
        />
      </button>

      {isOpen && (
        <div className="w-full filter-dropdown-container mt-[8px]">
          {/* Search Bar */}
          <div className="relative search-countries">
            <input
              type="text"
              className="w-full py-[12px] px-[16px] rounded-[10px] min-h-[48px] mb-[8px] border border-neutral-200 text-[16px] leading-[24px] font-normal text-neutral-400 focus:outline-[#E66927]"
              placeholder="Search Countries"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Country List */}
          {countriesToDisplay.length > 0 ? (
            countriesToDisplay.map((country) => (
              <label
                key={country.fldCountryId}
                className="cursor-pointer w-full flex items-center gap-[12px] py-[12px]"
              >
                <input
                  type="checkbox"
                  className="w-[20px] accent-primary-main h-[20px] cursor-pointer"
                />
                <div className="flex items-center gap-[8px]">
                  <ShowFlag
                    countryName={country.fldCountryName}
                    countryList={countries}
                  />
                  <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                    {country.fldCountryName}
                  </p>
                </div>
              </label>
            ))
          ) : (
            <span className="text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
              Not found
            </span>
          )}

          {/* Show More Button */}
          {filteredCountries.length > visibleCountries && (
            <button
              onClick={showMoreCountries}
              className="text-primary-main text-[16px] leading-[24px] font-normal py-[12px] w-full text-left"
            >
              Show More
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CountryFilterDropdown;
