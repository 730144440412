import { useNavigate } from "react-router-dom";

const useRedirection = () => {
  const navigate = useNavigate();

  const redirectAdminDashboard = () => {
    navigate("/admin-dashboard");
  };
  const redirectUserDashboard = () => {
    navigate("/supplier-dashboard");
  };
  const redirectLogin = () => {
    navigate("/");
  };
  const redirectToLoginPage=()=>{
    navigate("/userlogin");
  }
  const redirectPasswordReset=(token)=>{
    if(token){
      navigate(`/reset-password/${token}`)
    }
  }
  const redirectAccountProfile=()=>{
    navigate("/account-profile")
  }
  const redirectToAddDeals=()=>{
    navigate("/add-deals")
  }
  return {
    redirectAdminDashboard,
    redirectUserDashboard,
    redirectLogin,
    redirectPasswordReset,
    redirectAccountProfile,
    redirectToAddDeals,
    redirectToLoginPage
  };
};
export default useRedirection;