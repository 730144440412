import Cookies from "js-cookie";

export default function logout() {
  // Remove the token from cookies
  Cookies.remove("wd-team"); // 'wd-team' is the name of the cookie that stores the JWT
  Cookies.remove("username");
  Cookies.remove("remember_me");

  // Reload the page
  window.location.reload();

  console.log("User logged out, cookies removed, and page reloaded");
}
