import React, { useState } from "react";
import chevronDown from "../../../../../assets/images/v1/icons/chevron-down.svg";

const GradeDropDown = ({ title, options }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
      <button
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          {title}
        </h1>
        <img
          data-icon="arrow"
          src={chevronDown}
          className={`w-[24px] h-[24px] transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          alt="dropdown arrow"
        />
      </button>

      {isOpen && (
        <div className="w-full filter-dropdown-container">
          {options.map((option, index) => (
            <label
              key={index}
              className="cursor-pointer w-full flex items-center gap-[12px] py-[12px]"
            >
              <input
                type="checkbox"
                className="w-[20px] accent-primary-main h-[20px] cursor-pointer"
                name={option.value}
              />
              <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                {option.label}
              </p>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default GradeDropDown;
