import React from "react";

const DetailFooter = () => {
  return (
    <div>
      <div className="w-full max-w-[1440px] mx-auto phone:py-[72px] phone:px-[24px] phone:grid-cols-1 py-[80px] tablet:px-[137px] px-[120px] grid tablet:grid-cols-1 grid-cols-3 gap-[32px]">
        <div className="w-full">
          <img
            src="../assets/images/v1/icons/tour.png"
            className="w-[72px] h-[72px]"
            alt=""
            suitcase=""
          />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Take a tour
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            A better way to find wholesalers, dropshippers and wholesale
            products
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            Whether you’re successful retailer or a new starter, WhosaleDeals
            can make the difference. Would you like to get better products at
            lower prices? How about knowing how much you can earn at a glance,
            saving . your time and bad suprise?
          </p>
          <a
            href="https://www.wholesaledeals.co.uk/tour/What-Is-Wholesale-Deals-1.html"
            className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px]"
          >
            Take a tour
            <img
              src="../assets/images/v1/icons/arrow-up-right-orange.svg"
              className="w-[20px] h-[20px]"
              alt="arrow-up-right"
            />
          </a>
        </div>
        <div className="w-full">
          <img
            src="../assets/images/v1/icons/stories.png"
            className="w-[72px] h-[72px]"
            alt="conversation"
          />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Our success stories
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            See how Wholesale Deals has helped other traders like you succeed.
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            Since our launch in 2004 we’ve helped thousands of traders,
            companies and individuals find quality wholesalers and products. Our
            new deal tracker service and other improvements are the results of
            your feedback. Read our{" "}
            <a href="/v1/testimonial.html" className="text-[#E66927]">
              testimonial page
            </a>{" "}
            and, if your are a member,{" "}
            <a href="#" className="text-[#E66927]">
              drop us a line.
            </a>
          </p>
          <a
            href="/v1/testimonial.html"
            className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px]"
          >
            View testimonials
            <img
              src="../assets/images/v1/icons/arrow-up-right-orange.svg"
              className="w-[20px] h-[20px]"
              alt="arrow-up-right"
            />
          </a>
        </div>
        <div className="w-full">
          <img
            src="../assets/images/v1/icons/join.png"
            className="w-[72px] h-[72px]"
            alt="notes"
          />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Join with us
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            Ready to become a member or just coming back?
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            Save money with our new membership offers.
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56] mt-[12px]">
            <a href="#" className="text-[#E66927]">
              Upgrade to premium
            </a>
            , for full access and unlimited custom sourcing support
          </p>
          <a
            href="https://www.wholesaledeals.co.uk/login.php#tab-2"
            className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px]"
          >
            Register now
            <img
              src="../assets/images/v1/icons/arrow-up-right-orange.svg"
              className="w-[20px] h-[20px]"
              alt="arrow-up-right"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DetailFooter;
