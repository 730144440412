import React from "react";
import { Routes, Route } from "react-router-dom";
const DealWrapper = React.lazy(() =>
  import("../components/pages/deals/DealWrapper")
);
const CommonRoutes = () => {
  return (
    <Routes>
      <Route path="/deals/:id" element={<DealWrapper />} />
    </Routes>
  );
};

export default CommonRoutes;
