import React, { useEffect } from "react";
import AllCategoriesNav from "../../sharedComponent/AllCategories/components/AllCategoriesNav";
import CounterSection from "../../sharedComponent/CounterSection";

import Footer from "../../sharedComponent/Footer";
import MobileFilterComponent from "./components/MobileFilterComponent/MobileFilterComponent";
import { useDispatch, useSelector } from "react-redux";
import { getDeals } from "../../../store/slices/dealsSlice";
import Cookies from "js-cookie";
import RelatedSearches from "./components/RelatedSearches/RelatedSearches";
import EmailCta from "./components/EmailCTA.jsx/EmailCta";
import DealDetails from "./components/DealDetails/DealDetails";
import MostPopularOffers from "./components/MostPopularOffers/MostPopularOffers ";
import Deals from "./Deals";
const DealWrapper = () => {
  return (
    <div className="bg-[#F8FAFC] hello">
      <CounterSection />
      <AllCategoriesNav />
      <MobileFilterComponent />
      <Deals />
      <MostPopularOffers />
      <DealDetails />
      <RelatedSearches />
      <EmailCta />
      {/* End Form Login */}
      <Footer />
    </div>
  );
};

export default DealWrapper;
