import React, { useEffect, useState } from "react";
import { findCategoryPath } from "../../constant";
import OfferHeaderComponent from "../OfferHeaderComponent/OfferHeaderComponent";

const Breadcrumb = ({ id, categories }) => {
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);

  useEffect(() => {
    // Find the category path using the id
    const categoryPath = findCategoryPath(categories, id);
    console.log("categoryPath", categoryPath);
    // Map the category path to breadcrumb links
    const links = categoryPath.map((category, index) => ({
      label: category.name.replace(/&amp;/g, "&"), // Handle HTML entity
      href: `/category/${category.id}`, // Generate dynamic URL (example structure)
      isActive: index === categoryPath.length - 1, // Last item is the active one
    }));

    setBreadcrumbLinks(links);
  }, [id, categories]);

  useEffect(() => {
    console.log("breadcrumbLinks", breadcrumbLinks);
  }, [breadcrumbLinks]);
  return (
    <>
      <div className="w-full flex items-center gap-[4px]">
        {breadcrumbLinks.length > 0 ? (
          breadcrumbLinks.map((link, index) => (
            <React.Fragment key={index}>
              <a
                href={link.href}
                className={`text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal 
                ${
                  link.isActive
                    ? "text-neutral-900 font-medium"
                    : "text-neutral-500"
                }`}
              >
                {link.label}
              </a>
              {index < breadcrumbLinks.length - 1 && (
                <span className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500">
                  /
                </span>
              )}
            </React.Fragment>
          ))
        ) : (
          <span className="text-neutral-500">No breadcrumb available</span>
        )}
      </div>
      <OfferHeaderComponent breadcrumbs={breadcrumbLinks} />
    </>
  );
};

export default Breadcrumb;
