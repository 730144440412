export const getCategoryById = (categories, categoryId) => {
  let result = { parent: null, inner: null };

  // Helper function to recursively search through categories
  const searchCategories = (items, parent = null) => {
    for (let item of items) {
      if (item.id === categoryId) {
        result.inner = item; // Found the inner category
        result.parent = parent; // Set the parent if available
        return result;
      }

      // If children exist, search recursively
      if (item.children && item.children.length > 0) {
        const found = searchCategories(item.children, item); // Pass the current item as parent
        if (found.inner) {
          return found; // Return early if we found the category
        }
      }
    }
  };

  // Start the search
  searchCategories(categories);

  return result;
};

// Recursive function to find the category path
export const findCategoryPath = (categories, id) => {
  console.log("id", id);
  console.log("categories", categories);
  for (const category of categories) {
    // Base case: if the current category matches the id
    if (category.id == id) {
      console.log("categories", categories);
      return [category];
    }

    // Recursive case: check if this category has children
    if (category.children && category.children.length > 0) {
      const path = findCategoryPath(category.children, id);
      if (path.length > 0) {
        return [category, ...path]; // Prepend the current category to the path
      }
    }
  }
  return [];
};

export function trimArray(arr, count) {
  // Ensure that count is not greater than the array length
  if (count >= arr.length) {
    return arr;
  }

  // Trim the array to the specified count
  return arr.slice(0, count);
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getLowestPriceObject(prices) {
  // Convert fldPrice to a float for accurate comparison and find the lowest price object
  if (prices.length > 0) {
    return prices.reduce((lowest, current) => {
      return parseFloat(current.fldPrice) < parseFloat(lowest.fldPrice)
        ? current
        : lowest;
    });
  } else {
    return 0;
  }
}
