import React from "react";

const TopNav = () => {
  return (
    <div>
      <div className="block w-full topbanner phone:hidden">
        <div className="w-full phone:hidden px-[56px] py-[14px] tablet:py-[16px] flex items-center max-w-[1440px] mx-auto">
          <div className="block tablet:hidden phone:hidden">
            <img
              src="../assets/images/v1/homepage/flashsale-desktop.png"
              className="w-[235px] h-[40px]"
              alt="flashsale"
            />
          </div>
          <div className="hidden">
            <img
              src="../assets/images/v1/homepage/flash-tablet.png"
              className="w-[105px] h-[48px]"
              alt="flashsale"
            />
          </div>
          <div className="w-[466px] h-[52px] tablet:w-[121px] tablet:h-[52px] ml-[32px] tablet:ml-[0]">
            <div className="block tablet:hidden">
              <img
                src="../assets/images/v1/homepage/discount.png"
                className="w-full"
                alt="discount"
              />
            </div>
            <div className="hidden tablet:block">
              <img
                src="../assets/images/v1/homepage/discount-tablet.png"
                className="w-full"
                alt="discount"
              />
            </div>
          </div>
          <div className="w-fit h-[40px] ml-[37px] tablet:ml-[30px]">
            <div className="flex gap-[10px]">
              <div
                className="flex flex-col items-center gap-1"
                data-countdown="days"
              >
                <div className="flex gap-[2px]">
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={0}
                    >
                      0
                    </p>
                  </div>
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={1}
                    >
                      0
                    </p>
                  </div>
                </div>
                <p className="text-[8px] leading-[14px] text-white">DAYS</p>
              </div>
              <div
                className="flex flex-col items-center gap-1"
                data-countdown="hours"
              >
                <div className="flex gap-[2px]">
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={0}
                    >
                      0
                    </p>
                  </div>
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={1}
                    >
                      0
                    </p>
                  </div>
                </div>
                <p className="text-[8px] leading-[14px] text-white">HOURS</p>
              </div>
              <div
                className="flex flex-col items-center gap-1"
                data-countdown="minutes"
              >
                <div className="flex gap-[2px]">
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={0}
                    >
                      0
                    </p>
                  </div>
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={1}
                    >
                      0
                    </p>
                  </div>
                </div>
                <p className="text-[8px] leading-[14px] text-white">MINUTES</p>
              </div>
              <div
                className="flex flex-col items-center gap-1"
                data-countdown="seconds"
              >
                <div className="flex gap-[2px]">
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={0}
                    >
                      0
                    </p>
                  </div>
                  <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                    <p
                      className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                      style={{
                        boxShadow:
                          "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      data-time-block={1}
                    >
                      0
                    </p>
                  </div>
                </div>
                <p className="text-[8px] leading-[14px] text-white">SECONDS</p>
              </div>
            </div>
            {/* <img src="../assets/images/v1/homepage/count.svg" className="w-full" alt="count" /> */}
          </div>
          <div className="bg-white/20 w-[1px] h-[36px] mx-5" />
          <div className="flex flex-col items-center gap-1 mr-[32px] packages-banner-wrapper">
            <div className="flex gap-[2px]">
              <div>
                <p
                  className="text-white text-[32px] packages-value font-bold"
                  data-time-block={0}
                >
                  30
                </p>
              </div>
            </div>
            <p className="text-[8px] leading-[14px] text-white whitespace-nowrap">
              REMAINING PACKAGES
            </p>
          </div>
          <a
            href="https://wholesaledeals.vercel.app/wholesale/subscribe.html"
            className="w-fit no-underline rounded-[8px] border-none bg-[#E66927] hover:bg-[#f98344] transition-all duration-200 py-[8px] px-[24px] text-white font-semibold text-[16px] leading-[24px] ml-auto whitespace-nowrap"
          >
            Join Now
          </a>
        </div>
      </div>
      {/* Mobile Device */}
      <div className="hidden w-full phone:block">
        <div className="w-full flex items-center justify-between py-[32px] px-[24px]">
          <button id="hamburger-open" className="w-8 h-8">
            <img
              src="../assets/images/v1/homepage/hamburger-menu.svg"
              className="w-[24px] h-[16px]"
            />
          </button>
          <a href="/v1/index.html" className="no-underline">
            <img
              src="../assets/images/v1/brand/logo.svg"
              className="w-[327px] h-[32px]"
            />
          </a>
          <button>
            <img
              src="../assets/images/v1/homepage/search.svg"
              className="w-[32px] h-[32px]"
            />
          </button>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="w-full px-[24px] pb-[8px] sm:pb-[16px] pt-[16px] flex justify-between sm:justify-start sm:gap-6 items-center bg-[#E04C3F]">
            <div className="w-[95px] h-[16px]">
              <img
                src="../assets/images/v1/homepage/flashsale-desktop.png"
                className="w-full"
                alt="flashsale"
              />
            </div>
            <div className="w-[84px] h-[40px]">
              <img
                src="../assets/images/v1/homepage/discount-tablet.png"
                className="w-full"
                alt="discount"
              />
            </div>
          </div>
          <div className="w-full px-[24px] pb-[16px] pt-[8px] phone:pt-0 sm:pt-[16px] flex justify-between sm:justify-start sm:gap-6 bg-[#E04C3F]">
            <div className="w-fit h-[40px] ml-[37px] tablet:ml-[30px] phone:ml-0">
              <div className="flex gap-[10px]">
                <div
                  className="flex flex-col items-center gap-1.5"
                  data-countdown="days"
                >
                  <div className="flex gap-[2px] phone:py-3">
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={0}
                      >
                        0
                      </p>
                    </div>
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={1}
                      >
                        0
                      </p>
                    </div>
                  </div>
                  <p className="text-[8px] leading-[14px] text-white">DAYS</p>
                </div>
                <div
                  className="flex flex-col items-center gap-1.5"
                  data-countdown="hours"
                >
                  <div className="flex gap-[2px] phone:py-3">
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={0}
                      >
                        0
                      </p>
                    </div>
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={1}
                      >
                        0
                      </p>
                    </div>
                  </div>
                  <p className="text-[8px] leading-[14px] text-white">HOURS</p>
                </div>
                <div
                  className="flex flex-col items-center gap-1.5"
                  data-countdown="minutes"
                >
                  <div className="flex gap-[2px] phone:py-3">
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={0}
                      >
                        0
                      </p>
                    </div>
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={1}
                      >
                        0
                      </p>
                    </div>
                  </div>
                  <p className="text-[8px] leading-[14px] text-white">
                    MINUTES
                  </p>
                </div>
                <div
                  className="flex flex-col items-center gap-1.5"
                  data-countdown="seconds"
                >
                  <div className="flex gap-[2px] phone:py-3">
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={0}
                      >
                        0
                      </p>
                    </div>
                    <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                      <p
                        className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                        style={{
                          boxShadow:
                            "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                        }}
                        data-time-block={1}
                      >
                        0
                      </p>
                    </div>
                  </div>
                  <p className="text-[8px] leading-[14px] text-white">
                    SECONDS
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center gap-1 px-[4px]">
              <div className="flex flex-col items-center gap-1 px-[4px] packages-banner-wrapper">
                <div className="flex gap-[2px]">
                  <div>
                    <p
                      className="text-white text-[32px] packages-value font-bold"
                      data-time-block={0}
                    >
                      30
                    </p>
                  </div>
                </div>
                <p className="text-[8px] leading-[14px] text-white whitespace-nowrap">
                  REMAINING PACKAGES
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-[24px] py-[16px] bg-[#0355A5] phone:flex phone:text-center">
          <a
            href="https://wholesaledeals.vercel.app/wholesale/subscribe.html"
            className="py-[8px] no-underline px-[24px] text-[16px] font-semibold leading-[24px] text-white bg-[#E66927] rounded-[8px] w-full"
          >
            Join Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
