import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AdminAuthRoutes, UnAuthRoutes, UserAuthRoutes } from "./routes";
import ErrorPage from "./components/pages/Error/ErrorPage";
import Spinner from "./assets/spinner/Spinner";
import Navigation from "./components/sharedComponent/Navigations";
import { useDispatch } from "react-redux";
import { getCountryList } from "./store/slices/countryDropdownSlice";
import { getLanguageList } from "./store/slices/languageDropdownSlice";
import Cookies from "js-cookie";
import { checkUserToken } from "./store/api/validateToken";
import logout from "./shared/logout";
import CommonRoutes from "./routes/CommonRoutes";

function App() {
  const dispatch = useDispatch();

  const token = Cookies.get("wd-team");
  const validateToken = async () => {
    const isTokenValid = await checkUserToken();
    if (!isTokenValid) {
      logout();
    }
  };

  useEffect(() => {
    if (token) {
      validateToken();
    }
    dispatch(getCountryList());
    dispatch(getLanguageList());
  }, [dispatch, token]);

  return (
    <Suspense fallback={<Spinner />}>
      <Navigation />
      <Routes>
        {/* Protected Admin Routes */}
        {AdminAuthRoutes}

        {/* Public Routes */}
        {UnAuthRoutes}

        {/* Protected User Routes */}
        {UserAuthRoutes}

        {/* Common Routes */}
        <Route path="/*" element={<CommonRoutes />} />

        {/* Fallback Error Page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
