import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { MOST_POPULAR_RECENT_DEALS, LATEST_WHOLESALE_DEALS } from "../api";
export const mostPopularRecentDeals = createAsyncThunk(
  "/mostPopularRecentDeals",
  async () => {
    const response = await axios.get(MOST_POPULAR_RECENT_DEALS);
    return response.data;
  }
);

export const lastestWholeSaleDeals = createAsyncThunk(
  "/lastestWholeSaleDeals",
  async () => {
    const response = await axios.get(LATEST_WHOLESALE_DEALS);
    return response.data;
  }
);
const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const wholesalesuppliersSlice = createSlice({
  name: "wholesalesuppliers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(mostPopularRecentDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(mostPopularRecentDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(mostPopularRecentDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
      })
      .addCase(lastestWholeSaleDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(lastestWholeSaleDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lastestWholeSaleDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
      })

      
  },
});
export default wholesalesuppliersSlice.reducer;
