import { AuthGuard } from "../guards";
import { Route } from "react-router-dom";
import React from "react";
import DealWrapper from "../components/pages/deals/DealWrapper";

const BuyerProfile = React.lazy(() =>
  import("../components/pages/user/Buyer/Profile/BuyerProfileWrapper")
);
const ManageDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/ManageDealsWrapper")
);
const SupplierDashboard = React.lazy(() =>
  import("../components/pages/user/Common/DashBoard/SupplierDashboard")
);
const SupplierProfile = React.lazy(() =>
  import("../components/pages/user/Seller/Profile/SupplierProfileWrapper")
);
const AccountProfile = React.lazy(() =>
  import("../components/pages/user/Common/account-profile/AccountProfile")
);
const AffiliateEarnings = React.lazy(() =>
  import("../components/pages/user/Common/AffiliateEarnings/AffiliateEarnings")
);
const ManageBills = React.lazy(() =>
  import("../components/pages/user/Common/ManageBills/ManageBills")
);
const Messages = React.lazy(() =>
  import("../components/pages/user/Common/Messages/Messages")
);
const ManageServices = React.lazy(() =>
  import("../components/pages/user/Common/ManageServices/ManageServices")
);
const Orders = React.lazy(() =>
  import("../components/pages/user/Common/Orders/OrderList/OrdersWrapper")
);
const OrderMessages = React.lazy(() =>
  import(
    "../components/pages/user/Common/Orders/OrderMessages/OrderMessagesWrapper"
  )
);
const OrderDetails = React.lazy(() =>
  import(
    "../components/pages/user/Common/Orders/OrderDetails/OrderDetailsWrapper"
  )
);
const AddNewDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/AddDeals/AddNewDealsWrapper")
);

const CommonAuthRoutes = [
  <Route
    key="supplier-dashboard"
    path="/supplier-dashboard"
    element={<AuthGuard component={<SupplierDashboard />} />}
  />,
  <Route
    key="slash"
    path="/"
    element={<AuthGuard component={<SupplierDashboard />} />}
  />,
  <Route
    key="account-profile"
    path="/account-profile"
    element={<AuthGuard component={<AccountProfile />} />}
  />,
  <Route
    key="buyer-profile"
    path="/buyer-profile"
    element={<AuthGuard component={<BuyerProfile />} />}
  />,
  <Route
    key="supplier-profile"
    path="/supplier-profile"
    element={<AuthGuard component={<SupplierProfile />} />}
  />,
  <Route
    key="manage-deals"
    path="/manage-deals"
    element={<AuthGuard component={<ManageDeals />} />}
  />,

  <Route
    key="affiliate-earnings"
    path="/affiliate-earnings"
    element={<AuthGuard component={<AffiliateEarnings />} />}
  />,

  <Route
    key="manage-bills"
    path="/manage-bills"
    element={<AuthGuard component={<ManageBills />} />}
  />,

  <Route
    key="manage-services"
    path="/manage-services"
    element={<AuthGuard component={<ManageServices />} />}
  />,
  <Route
    key="messages"
    path="/messages"
    element={<AuthGuard component={<Messages />} />}
  />,
  <Route
    key="orders"
    path="/orders"
    element={<AuthGuard component={<Orders />} />}
  />,
  <Route
    key="orders-messages/:id"
    path="/orders-messages/:id"
    element={<AuthGuard component={<OrderMessages />} />}
  />,
  <Route
    key="/orders-details/:id"
    path="/orders-details/:id"
    element={<AuthGuard component={<OrderDetails />} />}
  />,
  <Route
    key="/add-deals"
    path="/add-deals"
    element={<AuthGuard component={<AddNewDeals />} />}
  />,
];

export default CommonAuthRoutes;
