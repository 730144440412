import React from "react";
import { Route } from "react-router-dom";
import { UnAuthGuard } from "../guards";
const Login = React.lazy(() => import("../components/pages/Login/Login"));
const WholeSaleSuppliers = React.lazy(() =>
  import("../components/pages/WholeSaleSuppliers/WholeSaleSuppliersWrapper")
);
const DealWrapper = React.lazy(() =>
  import("../components/pages/deals/DealWrapper")
);
const UnAuthRoutes = [
  <Route
    key="User Login"
    path="/userlogin"
    element={<UnAuthGuard component={<Login />} />}
  />,
  <Route
    key="Reset-password"
    path="/reset-password/:token"
    element={<UnAuthGuard component={<Login />} />}
  />,
  <Route
    key="homepage"
    path="/"
    element={<UnAuthGuard component={<WholeSaleSuppliers />} />}
  />,
  <Route
    key="deals"
    path="/deals/:id"
    element={<UnAuthGuard component={<DealWrapper />} />}
  />,
];

export default UnAuthRoutes;
