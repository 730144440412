import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // For navigating and updating the URL

// redux
import { useDispatch, useSelector } from "react-redux";

// store
import { getCategoriesList } from "../../../store/slices/categoriesDropdownSlice";

// components
import IconButton from "./components/IconButton";

// constant funcs
import { decodeHtmlEntities } from "./constants";

// Icons constants
import { chevronright } from "../../../assets/images/v1/icons";
import { useLocation } from "react-router-dom";
const AllCategories = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const categories = useSelector((state) => state.categories.data);
  const navigate = useNavigate(); // Hook to navigate and update URL

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategoriesList());
    }
  }, [dispatch, categories.length]);

  // State to track open menus
  const [openMenu, setOpenMenu] = useState({});

  // State to track selected outer and inner categories
  const [selectedOuterCategory, setSelectedOuterCategory] = useState(null);
  const [selectedInnerCategory, setSelectedInnerCategory] = useState(null);

  // Function to toggle nested menus
  const toggleMenu = (id) => {
    setOpenMenu((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // Function to update URL with the selected category ID
  const updateUrlWithCategory = (categoryId) => {
    navigate(`/deals/${categoryId}`); // Update the URL
  };
  // Track location changes and update menu state accordingly
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname == "/") {
      setOpenMenu({ main: true }); // Open the main menu
    } else if (location.pathname == "/deals") {
      setOpenMenu({}); // Close all menus
    }
  }, [location.pathname]);

  // Function to handle category click (outer and inner)
  const handleCategoryClick = (
    event,
    categoryId,
    categoryName,
    isInnerCategory = false,
    parentCategory = null
  ) => {
    if (isInnerCategory) {
      event.stopPropagation(); // Prevent the outer category from being clicked when inner category is clicked
    }

    console.log(
      `Category clicked: ${categoryName}, ID: ${categoryId}, isInnerCategory: ${isInnerCategory}`
    );

    if (isInnerCategory) {
      // If it's an inner category, set both the parent (outer) and inner categories
      setSelectedOuterCategory(parentCategory);
      setSelectedInnerCategory({ id: categoryId, name: categoryName });
    } else {
      // If it's an outer category, clear the inner selection and select only the outer category
      setSelectedOuterCategory({ id: categoryId, name: categoryName });
      setSelectedInnerCategory(null);
    }

    // Update the URL with the selected category ID
    updateUrlWithCategory(categoryId);

    // Close the menu after click
    setOpenMenu({});
  };

  useEffect(() => {
    console.log("selectedInnerCategory", selectedInnerCategory);
    console.log("selectedOuterCategory", selectedOuterCategory);
  }, [selectedOuterCategory, selectedInnerCategory]);

  // Recursive function to render nested categories
  const renderNestedMenu = (items, parentCategory) => {
    return (
      <ul className="absolute bg-white border border-gray-300 w-72 hidden group-hover:block transition-all duration-300">
        {items.map((item) => (
          <li
            key={item.id}
            className={`relative group p-4 hover:bg-[#FAF1DC] cursor-pointer flex justify-between items-center transition-all duration-300 ${
              selectedInnerCategory && selectedInnerCategory.id === item.id
                ? "bg-[#FAF1DC] font-bold"
                : ""
            }`} // Apply style for selected inner category
            onMouseEnter={() => toggleMenu(item.id)} // Open on hover
            onMouseLeave={() => toggleMenu(item.id)} // Close on leave
            onClick={
              (event) =>
                handleCategoryClick(
                  event,
                  item.id,
                  item.name,
                  true,
                  parentCategory
                ) // Set isInnerCategory true for inner items and pass event
            }
          >
            <span>
              {decodeHtmlEntities(item.name)} ({item.children.length})
            </span>
            {/* Show ">" at the end of the li if it has children */}
            {item.children.length > 0 && (
              <span className="ml-2">
                <IconButton iconOnly={true} tabName={"chevronright"} />
              </span>
            )}
            {/* Render nested menu for children */}
            {item.children.length > 0 && openMenu[item.id] && (
              <div className="absolute left-full top-0 z-10">
                {renderNestedMenu(item.children, {
                  id: item.id,
                  name: item.name,
                })}{" "}
                {/* Pass the correct parent category */}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="relative">
      {/* Button to open the dropdown */}
      <button
        onClick={() => toggleMenu("main")} // Toggle main menu
        className="allcategories w-[314px] py-[12px] border-r border-neutral-200 min-w-[314px] flex items-center gap-[10px]"
        aria-expanded={Object.keys(openMenu).length > 0 ? "true" : "false"}
      >
        <img src="../assets/images/v1/icons/menu.svg" alt="menu" />
        <p className="font-semibold text-[16px] leading-[24px] text-neutral-900">
          All Categories
        </p>
      </button>

      {/* Dropdown Menu */}
      <ul
        className={`absolute mt-0 bg-white border border-gray-300 w-full transition-all duration-300 ease-in-out z-10 ${
          openMenu["main"] ? "block" : "hidden"
        }`}
      >
        {categories.map((category) => (
          <li
            key={category.id}
            className={`group relative p-5 hover:bg-[#FAF1DC] hover:border-l-4 hover:border-orange-500 cursor-pointer flex justify-between items-center transition-all duration-300 ${
              selectedOuterCategory && selectedOuterCategory.id === category.id
                ? "bg-[#FAF1DC] font-bold"
                : ""
            }`} // Apply style for selected outer category
            onMouseEnter={() => toggleMenu(category.id)} // Open on hover
            onMouseLeave={() => toggleMenu(category.id)} // Close on leave
            onClick={(event) =>
              handleCategoryClick(event, category.id, category.name, false)
            } // Set isInnerCategory to false for outer items and pass event
          >
            <span>
              <IconButton tabName={category.name} />
            </span>
            {/* Show ">" at the end of the li if it has children */}
            {category.children.length > 0 && (
              <span className="ml-2">
                <IconButton iconOnly={true} tabName={"chevronright"} />
              </span>
            )}
            {/* Render nested menu for children */}
            {category.children.length > 0 && openMenu[category.id] && (
              <div className="absolute left-full top-0 z-10">
                {renderNestedMenu(category.children, {
                  id: category.id,
                  name: category.name,
                })}{" "}
                {/* Pass correct parent category */}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AllCategories;
