import React from "react";

const SubscribeCard = ({ classNames }) => {
  return (
    <div
      className={`w-full  rounded-[16px] p-[24px] grid gap-[24px] ${classNames}`}
    >
      {/* Subscribe Icon */}
      <img
        src="../../../../../assets/images/v1/deals/subscribe.svg"
        className="w-[80px] h-[68px] mx-auto"
        alt="subscribe-icon"
      />

      {/* Text Section */}
      <div>
        <h3 className="font-semibold text-[18px] leading-[27px] text-white text-center mb-[8px]">
          Subscribe to get all access
        </h3>
        <p className="text-white opacity-80 text-[14px] leading-[21px] text-center">
          Now you can buy wholesale and dropship products at 45% to 95% margins,
          safe in the knowledge they are already selling successfully.
        </p>
      </div>

      {/* Subscribe Button */}
      <a
        href="https://wholesaledeals.vercel.app/wholesale/subscribe.html"
        className="flex items-center justify-center text-primary-main bg-white rounded-[8px] text-[14px] leading-[21px] font-semibold w-[160px] h-[40px] text-center mx-auto hover:bg-neutral-gray transition-all duration-200"
      >
        Subscribe
      </a>
    </div>
  );
};

export default SubscribeCard;
