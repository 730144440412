import { useState } from "react";
import chevronDown from "../../../../../assets/images/v1/icons/chevron-down.svg";

const FilterDropdown = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="filter-dropdown w-full py-[8px] px-[20px]">
      <button
        id="shoes-filter"
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          Sports, hobbies &amp; leisure - Category
        </h1>
        <img
          data-icon="arrow"
          src={chevronDown}
          className={`w-[24px] h-[24px] transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          alt="dropdown arrow"
        />
      </button>

      {isOpen && (
        <div className="w-full filter-dropdown-container mt-[8px]">
          {[
            "Stock lot shoes",
            "Baby's Shoes",
            "Children's Shoes",
            "Woman's Shoes",
            "Men's Shoes",
          ].map((category) => (
            <button
              key={category}
              className="w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px] hover:text-neutral-900"
            >
              {category}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
