// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import passwordSlice from "./slices/passwordSlice";
import countryDropdownSlice from "./slices/countryDropdownSlice";
import languageDropdownSlice from "./slices/languageDropdownSlice";
import buyerSlice from "./slices/buyerSlice";
import categoriesDropdownSlice from "./slices/categoriesDropdownSlice";
import currenciesDropdownSlice from "./slices/currenciesDropdownSlice";
import supplierSlice from "./slices/supplierSlice";
import dealsReducer from "./slices/dealsSlice";
import elasticSearchReducer from "./slices/elasticSearchSlice";
import commonDropDownReducer from "./slices/commonDropDownSlice";
import addDealsReducer from "./slices/addDealsSlice";
import commonDealsReducer from "./slices/commonDealsSlice";
import wholesalesuppliersReducer from "./slices/wholesalesuppliersSlice";
import scrollerDealsSlice from "./slices/scrollDealsSlice";
const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    password: passwordSlice,
    country: countryDropdownSlice,
    language: languageDropdownSlice,
    buyer: buyerSlice,
    categories: categoriesDropdownSlice,
    currencies: currenciesDropdownSlice,
    supplier: supplierSlice,
    deals: dealsReducer,
    elastic: elasticSearchReducer,
    common: commonDropDownReducer,
    addeals: addDealsReducer,
    common_deals: commonDealsReducer,
    scroller_deals: scrollerDealsSlice,
    wholesalesuppliers: wholesalesuppliersReducer,
  },
});

export default store;
