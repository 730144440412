// components
import ImageComponent from "../ImageComponent";

// icons
import {
  babynormal,
  chevronright,
  computing,
  consumerelectronics,
  health,
  homegarden,
  jwellery,
  leisure,
  mobile,
  office,
  police,
  shirt,
  sports,
  surplus,
  toys,
} from "../../../assets/images/v1/icons";

export const getIcons = (tabName) => {
  switch (tabName) {
    case "Baby Products":
      return <ImageComponent src={babynormal} alt={tabName} />; // Home icon
    case "Clothing &amp; Fashion":
      return <ImageComponent src={shirt} alt={"shirt"} />; // Settings icon
    case "Computing":
      return <ImageComponent src={computing} alt={"computing"} />; // Profile icon
    case "Consumer Electronics":
      return (
        <ImageComponent src={consumerelectronics} alt={"consumerelectronics"} />
      );
    case "Health &amp; Beauty":
      return <ImageComponent src={health} alt={"health"} />;
    case "Home &amp; Garden":
      return <ImageComponent src={homegarden} alt={"homegarden"} />;
    case "Jewellery &amp; Watches":
      return <ImageComponent src={jwellery} alt={"jwellery"} />;
    case "Leisure &amp; Entertainment":
      return <ImageComponent src={leisure} alt={"leisure"} />;
    case "Mobile &amp; Home Phones":
      return <ImageComponent src={mobile} alt={"mobile"} />;
    case "Office &amp; Business":
      return <ImageComponent src={office} alt={"office"} />;
    case "Police Auctions &amp; Auction Houses":
      return <ImageComponent src={police} alt={"police"} />;
    case "Sports &amp; Fitness":
      return <ImageComponent src={sports} alt={"sports"} />;
    case "Surplus &amp; Stocklots":
      return <ImageComponent src={surplus} alt={"surplus"} />;
    case "Toys &amp; Games":
      return <ImageComponent src={toys} alt={"toys"} />;
    case "chevronright":
      return <ImageComponent src={chevronright} alt={"chevronright"} />;
    default:
      return "❓"; // Default icon if tabName doesn't match any case
  }
};

export const decodeHtmlEntities = (str) => {
  return str.replace(/&amp;/g, "&");
};
