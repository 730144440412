import React from "react";

// constants
import { decodeHtmlEntities, getIcons } from "../constants";

export default function IconButton({ tabName, iconOnly = false }) {
  return (
    <div className="flex items-center space-x-2">
      {iconOnly ? (
        <span>{getIcons(tabName)}</span>
      ) : (
        <>
          <span>{getIcons(tabName)}</span>
          <span>{decodeHtmlEntities(tabName)}</span>
        </>
      )}
    </div>
  );
}
