import React, { useState } from "react";

const OfferHeaderComponent = ({ breadcrumbs }) => {
  const [liked, setLiked] = useState(false);

  const handleLikeToggle = () => {
    setLiked(!liked);
  };

  function createHeadingFromBreadcrumbs(breadcrumbs) {
    // Extract labels from the breadcrumb array and join them with commas
    if (breadcrumbs && breadcrumbs.length) {
      return breadcrumbs.map((crumb) => crumb.label).join(", ");
    }
    return [];
  }

  const heading = createHeadingFromBreadcrumbs(breadcrumbs);
  console.log("heading", heading);
  return (
    <div className="w-full flex phone:flex-col phone:items-start items-center gap-[8px] my-[12px]">
      <div className="flex items-center gap-[8px]">
        <div className="relative flex justify-center block">
          {/* Popover */}
          <div className="popover z-[10] hidden absolute top-[-28px] rounded-[8px] bg-[#475569] whitespace-nowrap h-[28px] flex items-center px-[10px]">
            <span className="popover-text text-[12px] leading-[18px] tracking-[0.4px] text-white"></span>
          </div>
          {/* Like Button */}
          <button className="like-btn normal" onClick={handleLikeToggle}>
            <img
              data-icon="normal"
              src={
                liked
                  ? "../../../../../assets/images/v1/icons/heart-rounded.svg"
                  : "../../../../../assets/images/v1/icons/heart-rounded.svg"
              }
              className="w-[24px] h-[24px]"
              alt={liked ? "heart-liked" : "heart"}
            />
            <img
              data-icon="hover"
              src={
                liked
                  ? "./images/heart-rounded-liked-hover.svg"
                  : "./images/heart-rounded-liked-hover.svg"
              }
              className="w-[24px] h-[24px] hidden"
              alt={liked ? "heart-liked-hover" : "heart-hover"}
            />
          </button>
        </div>
        {/* Title */}
        <h3 className="text-[24px] leading-[33.6px] phone:text-[20px] phone:leading-[27px] font-bold text-neutral-900">
          {heading}
        </h3>
      </div>
      {/* Offer Count */}
      <p className="text-neutral-500 text-[18px] leading-[27px] phone:text-[14px] phone:leading-[21px] font-medium">
        (123,893 offers)
      </p>
    </div>
  );
};

export default OfferHeaderComponent;
