import React from "react";
import Card from "../Card/Card"; // Assuming Card is in the same directory or adjust the path accordingly

const MostPopularOffers = () => {
  const products = [
    {
      image: "../../../../../assets/images/v1/homepage/shoes.png",
      title:
        "Original Adidas EG8444 Torsion Trdc Womens Running Trainers Sneakers Jeans",
      price: "18.95",
      markup: "78.57%",
      rrp: "183.78",
      grossProfit: "793.10",
      featuredDate: "19/09/2023",
      comparison: [
        {
          name: "Amazon",
          logo: "../../../../../assets/images/v1/homepage/amazon.svg",
          price: "12.35",
        },
        {
          name: "Ebay",
          logo: "../../../../../assets/images/v1/homepage/ebay.svg",
          price: "12.35",
        },
      ],
    },
    {
      image: "../../../../../assets/images/v1/homepage/shoes.png",
      title: "Adidas Feel The Chill Ice Dive 3pcs Gift Set",
      price: "20.99",
      markup: "65.23%",
      rrp: "199.99",
      grossProfit: "550.20",
      featuredDate: "20/09/2023",
      comparison: [
        {
          name: "Amazon",
          logo: "../../../../../assets/images/v1/homepage/amazon.svg",
          price: "14.50",
        },
        {
          name: "Ebay",
          logo: "../../../../../assets/images/v1/homepage/ebay.svg",
          price: "14.80",
        },
      ],
    },
    {
      image: "../../../../../assets/images/v1/homepage/shoes.png",
      title: "Seagate Portable 2TB External Hard Drive",
      price: "48.99",
      markup: "55.00%",
      rrp: "100.00",
      grossProfit: "300.50",
      featuredDate: "21/09/2023",
      comparison: [
        {
          name: "Amazon",
          logo: "../../../../../assets/images/v1/homepage/amazon.svg",
          price: "50.00",
        },
        {
          name: "Ebay",
          logo: "../../../../../assets/images/v1/homepage/ebay.svg",
          price: "49.99",
        },
      ],
    },
    {
      image: "../../../../../assets/images/v1/homepage/shoes.png",
      title: "Seagate Portable 2TB External Hard Drive",
      price: "48.99",
      markup: "55.00%",
      rrp: "100.00",
      grossProfit: "300.50",
      featuredDate: "21/09/2023",
      comparison: [
        {
          name: "Amazon",
          logo: "../../../../../assets/images/v1/homepage/amazon.svg",
          price: "50.00",
        },
        {
          name: "Ebay",
          logo: "../../../../../assets/images/v1/homepage/ebay.svg",
          price: "49.99",
        },
      ],
    },
  ];

  return (
    <div className="w-full bg-white phone:hidden">
      <div className="w-full max-w-[1440px] mx-auto pt-[72px] px-[56px] pb-[40px] tablet:pb-[68px] phone:py-[48px] phone:px-[24px]">
        <div className="w-full flex phone:flex-col phone:gap-[24px] phone:items-start items-center justify-between">
          <div className="w-fit flex items-center phone:items-start tablet:items-start gap-[12px]">
            <img
              src="../../../../../assets/images/v1/icons/medal.svg"
              className="w-[23px] h-[32px] phone:w-[18px] phone:h-[24px]"
              alt="medal"
            />
            <h3 className="text-[32px] phone:text-[20px] phone:leading-[27px] tablet:text-[32px] tablet:leading-[40px] tablet:max-w-[430px] font-bold leading-[40px] text-neutral-900">
              Most Popular Offers
            </h3>
          </div>
          <button
            className="w-fit flex phone:ml-[30px] items-center gap-[6px]"
            jf-ext-button-ct="explore all products"
          >
            <p className="text-[16px] leading-[24px] font-medium text-primary-main">
              Explore All Products
            </p>
            <img
              src="../../../../../assets/images/v1/icons/chevron-orange.svg"
              className="w-[16px] h-[16px]"
              alt="chevron"
            />
          </button>
        </div>

        <div className="w-full phone:hidden grid tablet:grid-cols-2 grid-cols-4 gap-[20px] mt-[48px]">
          {products.map((product, index) => (
            <Card
              key={index}
              image={product.image}
              title={product.title}
              price={product.price}
              markup={product.markup}
              rrp={product.rrp}
              grossProfit={product.grossProfit}
              featuredDate={product.featuredDate}
              comparison={product.comparison}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MostPopularOffers;
