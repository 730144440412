import React, { useState } from "react";
import filterIcon from "../../../../../assets/images/v1/deals/filter.svg";
import closeIcon from "../../../../../assets/images/v1/homepage/close.svg";
import subscribeIcon from "../../../../../assets/images/v1/deals/subscribe.svg";
import countryIcon from "../../../../../assets/images/v1/deals/country/indonesia.svg";

const MobileTabletFilterComponent = () => {
  const [filterOpen, setFilterOpen] = useState(false);

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  return (
    <div className="deals-filter">
      {/* Filter Button for screens up to 1280px */}
      <div className="flex lg:hidden items-center gap-2 py-3">
        <p className="text-neutral-500 font-normal text-sm leading-6">
          Filter Deals:
        </p>
        <button
          id="openfilter"
          className="flex items-center gap-2 py-2"
          onClick={toggleFilter}
        >
          <img src={filterIcon} className="w-5 h-5" alt="filter" />
          <p className="font-bold text-sm leading-6 text-neutral-900">
            View all available filters
          </p>
        </button>
      </div>

      {/* Filter Panel for Mobile and Tablet (up to 1280px) */}
      {filterOpen && (
        <div
          id="filterpanel"
          className="w-full h-screen bg-black/40 fixed z-30 top-0 left-0 overflow-y-scroll lg:max-w-screen-lg lg:mx-auto lg:max-h-[90vh]"
        >
          <div className="block w-full bg-white absolute z-50 top-0 left-0 lg:max-w-screen-lg lg:mx-auto lg:max-h-[90vh] lg:top-10">
            {/* Filter Header */}
            <div className="w-full px-4 py-4 flex items-center justify-between bg-white shadow-md">
              <h3 className="font-bold text-lg leading-7 text-neutral-900">
                Filter
              </h3>
              <button className="close-filterpanel" onClick={toggleFilter}>
                <img src={closeIcon} className="w-8 h-8" alt="close" />
              </button>
            </div>

            {/* Filter Content */}
            <div className="w-full px-4 pb-5">
              {/* Category Filter */}
              <div className="w-full md:grid md:grid-cols-2 lg:grid-cols-2 gap-6">
                {/* Column 1: Categories */}
                <div className="space-y-3">
                  <h1 className="font-bold text-base py-4 leading-6 text-left text-neutral-900">
                    Sports, hobbies &amp; leisure - Category
                  </h1>
                  <button className="w-full text-left text-base leading-6 text-neutral-600 font-normal py-3">
                    Stock lot shoes
                  </button>
                  <button className="w-full text-left text-base leading-6 text-neutral-600 font-normal py-3">
                    Baby's Shoes
                  </button>
                  <button className="w-full text-left text-base leading-6 text-neutral-600 font-normal py-3">
                    Children's Shoes
                  </button>
                  <button className="w-full text-left text-base leading-6 text-neutral-600 font-normal py-3">
                    Woman's Shoes
                  </button>
                  <button className="w-full text-left text-base leading-6 text-neutral-600 font-normal py-3">
                    Men's Shoes
                  </button>
                </div>

                {/* Column 2: Grades */}
                <div className="space-y-3">
                  <h1 className="font-bold text-base py-4 leading-6 text-left text-neutral-900">
                    Grade
                  </h1>
                  <div className="flex items-center gap-3 py-2">
                    <input
                      type="checkbox"
                      className="w-5 h-5 accent-primary-main cursor-pointer"
                    />
                    <p className="text-base leading-6 text-neutral-600 font-normal">
                      New
                    </p>
                  </div>
                  <div className="flex items-center gap-3 py-2">
                    <input
                      type="checkbox"
                      className="w-5 h-5 accent-primary-main cursor-pointer"
                    />
                    <p className="text-base leading-6 text-neutral-600 font-normal">
                      Used
                    </p>
                  </div>
                  <div className="flex items-center gap-3 py-2">
                    <input
                      type="checkbox"
                      className="w-5 h-5 accent-primary-main cursor-pointer"
                    />
                    <p className="text-base leading-6 text-neutral-600 font-normal">
                      Mix / Return
                    </p>
                  </div>
                  <div className="flex items-center gap-3 py-2">
                    <input
                      type="checkbox"
                      className="w-5 h-5 accent-primary-main cursor-pointer"
                    />
                    <p className="text-base leading-6 text-neutral-600 font-normal">
                      Outlet
                    </p>
                  </div>
                  <div className="flex items-center gap-3 py-2">
                    <input
                      type="checkbox"
                      className="w-5 h-5 accent-primary-main cursor-pointer"
                    />
                    <p className="text-base leading-6 text-neutral-600 font-normal">
                      Refurbished
                    </p>
                  </div>
                  <div className="flex items-center gap-3 py-2">
                    <input
                      type="checkbox"
                      className="w-5 h-5 accent-primary-main cursor-pointer"
                    />
                    <p className="text-base leading-6 text-neutral-600 font-normal">
                      Damaged
                    </p>
                  </div>
                </div>
              </div>

              {/* Countries Filter */}
              <div className="w-full space-y-3 pt-6">
                <h1 className="font-bold text-base py-4 leading-6 text-left text-neutral-900">
                  All Countries
                </h1>
                <div className="relative">
                  <input
                    type="text"
                    className="w-full py-3 px-4 rounded-md mb-2 border border-neutral-200 text-base leading-6 font-normal text-neutral-400 focus:outline-none"
                    placeholder="Search Countries"
                  />
                </div>
                {/* Example of country filter */}
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    className="w-5 h-5 accent-primary-main cursor-pointer"
                  />
                  <div className="flex items-center gap-2">
                    <img src={countryIcon} alt="Indonesia" />
                    <p className="text-base leading-6 text-neutral-600 font-normal">
                      Indonesia
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Subscribe Section */}
            <div className="w-full py-5 px-4">
              <div className="w-full px-5 py-4 bg-primary-main rounded-lg text-center">
                <img
                  src={subscribeIcon}
                  alt="Subscribe"
                  className="w-20 h-20 mx-auto"
                />
                <h3 className="font-semibold text-lg leading-7 text-white">
                  Subscribe to get all access
                </h3>
                <p className="text-white opacity-80 text-base leading-6">
                  Now you can buy wholesale and dropship products at 45% to 95%
                  margins, safe in the knowledge they are already selling
                  successfully.
                </p>
                <button className="bg-white text-primary-main rounded-md text-base font-semibold mt-4 px-6 py-2">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTabletFilterComponent;
