import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SCROLLER_DEALS } from "../api";

// Thunk to fetch scroller deals
export const getScrollerDeals = createAsyncThunk(
  "/getScrollerDeals",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${SCROLLER_DEALS}`);
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.log("Error fetching scroll deals:", error);
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);

// Initial state for the scroller deals slice
const initialState = {
  data: {}, // Object to hold fetched deals
  isLoading: true, // Loading state to track async operations
  currentPage: 1,
  itemsPerPage: 10,
  totalPage: 0,
  error: null, // Holds any error that occurs
};

// Creating the slice
const scrollerDealsSlice = createSlice({
  name: "scroller_deals",
  initialState,
  reducers: {
    setPage: (state, action) => {
      // Logic to set the page if you're dealing with paginated data
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScrollerDeals.pending, (state) => {
        // Handle the loading state when the async request starts
        state.isLoading = true;
        state.error = null; // Reset errors
      })
      .addCase(getScrollerDeals.fulfilled, (state, action) => {
        // Handle the case when the request is successful
        state.isLoading = false;
        state.data = action.payload.data; // Store the fetched data
      })
      .addCase(getScrollerDeals.rejected, (state, action) => {
        // Handle the case when the request fails
        state.isLoading = false;
        state.error = action.payload || "Failed to load deals.";
      });
  },
});

// Exporting the action creators and the reducer
export const { setPage } = scrollerDealsSlice.actions;

export default scrollerDealsSlice.reducer;
