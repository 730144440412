import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { eyeClose, eyeOpen } from "../../../../assets/images/v1/single";
import { chevronDown } from "../../../../assets/images/v1/icons";
import Spinner from "../../../../assets/spinner/Spinner";
import { LoginUser } from "../../../../store/slices/authSlice";
import { useRedirection, useUser } from "../../../../hooks";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "../../../../hooks/cookies";
import { getUser } from "../../../../store/slices/userSlice";
import { Link } from "react-router-dom";
const LoginHeader = ({ setForgotPass, ShowRegisterForm }) => {
  const [userNameHeader, setUserNameHeader] = useState(null); // State to track the username
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { redirectUserDashboard } = useRedirection();
  const [cPasswordEye, setCPasswordEye] = useState(eyeOpen);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [passwordEye, setPasswordEye] = useState(eyeOpen);
  const { returnUserId, getUserToken } = useUser();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [passEye, setPassEye] = useState(eyeOpen);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  // need to check after client check
  const [isLoading, setIsLoading] = useState(false);
  const [checkAuth, setCheckAuth] = useState(false);
  const [dataValues, setDataValues] = useState({});
  useEffect(() => {
    fetchUserData();
  }, []);
  useEffect(() => {
    fetchUserData();
  }, [location.pathname]);
  const showRegistretionForm = () => {
    ShowRegisterForm();
    setIsOpen(false);
  };

  const fetchUserData = async () => {
    // Show loading while fetching user
    const userId = returnUserId();
    const token = getUserToken();

    if (userId) {
      setIsLoading(true);
      await dispatch(getUser({ userId, token }));
      setIsLoading(false); // Show loading while fetching user
    }
  };
  const userData = useSelector((state) => state.user);
  // Checking is cookies present or not
  useEffect(() => {
    if (!userData.isLoading && userData.data) {
      setCheckAuth(true);
      setDataValues(userData.data.user || {}); // Safely access userData.data.user
    }
  }, [userData.isLoading, userData.data]);

  const userName =
    isLoading || !userData?.data?.user
      ? ""
      : `${userData.data.user.fldFirstName ?? ""} ${
          userData.data.user.fldLastName ?? ""
        }`.trim();
  // end need to check after client check
  // Check for the user_name cookie on component mount
  useEffect(() => {
    const storedUserName = Cookies.get("username");
    setUserNameHeader(storedUserName); // If user is logged in, set the username
  }, []);

  const handleLogoutHeader = () => {
    // Remove the cookies on logout
    Cookies.remove("username");
    Cookies.remove("wd-team");
    Cookies.remove("remember_me");
    setUserNameHeader(null); // Reset the username to null
    window.location.reload();
  };

  const toggleForgotPassword = () => {
    console.log("Closing Modal");
    setIsOpen(false);
    setForgotPass();
    toggleModal();
  };

  //   Handle form submit
  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const response = await dispatch(LoginUser(values));
      setIsLoading(true);
      if (
        response.type === "login/fulfilled" &&
        response.payload.message === "Login successful"
      ) {
        // Store username in cookies upon successful login
        Cookies.set("username", values.username, { expires: 7 }); // Store for 7 days
        setIsLoading(false);
        setIsLoginSuccess(true);
        setIsOpen(false);
        redirectUserDashboard();
      } else if (response.type === "login/rejected") {
        setFieldError(
          "general",
          response.payload || "Login failed. Please try again."
        );
        setIsLoading(false);
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Login failed:", error);
      setFieldError("general", "An error occurred. Please try again.");
      setSubmitting(false);
    }
    setIsLoading(false);
  };

  // Toggling dropdown
  const toggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };
  //   Toggling Modal
  const toggleModal = (e) => {
    setIsOpen(!isOpen);
  };

  // Toggling Register Modal
  const toggleRegisterModal = (e) => {
    e.preventDefault();
    setIsRegisterModalOpen(!isRegisterModalOpen);
    handleLogoutHeader();
  };
  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPassEye(passEye === eyeOpen ? eyeClose : eyeOpen);
  };

  // Validation Schema Login
  const validationSchema1 = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  // Register formValidation scema
  // Validation Schema
  const validationSchema = Yup.object({
    username: Yup.string().required("Username is Required"),
    gender: Yup.string()
      .oneOf(["male", "female"], "Please choose salutation")
      .required("Please Choose Salutation"),
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is Required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        "Password should be at least 8 characters, include at least one letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is Required"),
    agree: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
  });

  const isAuthPage = getToken();
  const isSupplierDashboard = location.pathname.includes("/supplier-dashboard");
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <Formik
        initialValues={{ username: "", password: ",remember_me: false" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, error }) => (
          <>
            <div className="w-full phone:hidden max-w-[1440px] mx-auto tablet:flex tablet:flex-col tablet:pb-[24px]">
              <div className="w-full px-[56px] py-[28px] flex items-center tablet:justify-between">
                <Link to="/" className="block flex-none w-[237px] h-[40px]">
                  <img
                    src="../assets/images/v1/brand/logo.svg"
                    className="w-[237px] h-[40px]"
                    alt="brand"
                  />
                </Link>

                <div className="tablet:hidden flex items-center w-[654px] relative ml-[74px]">
                  {/* Product button */}
                  <div className="relative">
                    <button
                      onClick={toggleDropdown}
                      className="flex gap-1 items-center text-[14px] text-neutral-700 font-semibold py-2 px-4 rounded focus:outline-none border border-r-[0px] border-neutral-300"
                    >
                      Product{" "}
                      <img
                        src={chevronDown}
                        className={isDropDownOpen ? "rotate-180" : ""}
                      ></img>
                    </button>

                    {/* Dropdown menu */}
                    {isDropDownOpen && (
                      <div className="absolute mt-3 w-40 bg-white rounded-md shadow-lg z-10">
                        <ul>
                          <li className="hover:bg-[#FAF1DC] ps-5 py-2 cursor-pointer">
                            Deal
                          </li>
                          <li className="hover:bg-[#FAF1DC] ps-5 py-2 cursor-pointer">
                            Supplier
                          </li>
                          <li className="hover:bg-[#FAF1DC] ps-5 py-2 cursor-pointer">
                            Wholesaler
                          </li>
                          <li className="hover:bg-[#FAF1DC] ps-5 py-2 cursor-pointer">
                            Distributor
                          </li>
                          <li className="hover:bg-[#FAF1DC] ps-5 py-2 cursor-pointer">
                            Liquidator
                          </li>
                          <li className="hover:bg-[#FAF1DC] ps-5 py-2 cursor-pointer">
                            Drop Shippers
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <input
                    type="search"
                    className="w-full py-[8px] px-[16px] rounded-r-[8px] border border-neutral-300 text-[14px] leading-[21px]"
                    placeholder="What are you looking for ?"
                    name="search"
                    id="search"
                  />
                  <img
                    data-name="search-icon"
                    src="../assets/images/v1/icons/search.svg"
                    className="w-[18px] h-[18px] absolute top-[11px] right-[16px]"
                    alt="search"
                  />
                </div>

                <div className="justify-end hidden w-full tablet:flex">
                  <div className="w-fit flex items-center gap-[12px] ml-[48px] pr-[24px] border-r border-neutral-200">
                    <div className="w-fit p-[8px]">
                      <img
                        src="../assets/images/v1/icons/user.svg"
                        className="w-[24px] h-[24px]"
                        alt="user"
                      />
                    </div>
                    <div className="grid">
                      <h3 className="font-normal text-[14px] leading-[21px] text-neutral-500">
                        Hello!
                      </h3>
                      <div className="flex items-center gap-2">
                        <div className="relative popover">
                          <a className="popover-trigger text-primary-main font-normal text-[16px] leading-[24px]">
                            {/* {userName===null?<span>Login</span>:<span>{userName}</span>} */}
                            {/* {userNameHeader===null?<span>Login</span>:<span>{userNameHeader}</span>} */}
                            {isAuthPage ? "Login" : userNameHeader}
                          </a>
                          <div className="popover-menu hidden absolute right-2 z-10 top-[100%] min-w-[400px] mt-2 phone:hidden p-6 shadow-sm bg-white border border-neutral-200 rounded-lg">
                            <div className="flex flex-col gap-3 phone:gap-[16px]">
                              <div className="flex items-center gap-2">
                                <svg
                                  width={24}
                                  height={25}
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g opacity="0.12">
                                    <path
                                      d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
                                      fill="#E66927"
                                    />
                                    <path
                                      d="M16 17.5H8C5.79086 17.5 4 19.2909 4 21.5C4 22.0523 4.44772 22.5 5 22.5H19C19.5523 22.5 20 22.0523 20 21.5C20 19.2909 18.2091 17.5 16 17.5Z"
                                      fill="#E66927"
                                    />
                                  </g>
                                  <path
                                    d="M4.00002 22.3174C4.6026 22.5 5.41649 22.5 6.8 22.5H17.2C18.5835 22.5 19.3974 22.5 20 22.3174M4.00002 22.3174C3.87082 22.2783 3.75133 22.2308 3.63803 22.173C3.07354 21.8854 2.6146 21.4265 2.32698 20.862C2 20.2202 2 19.3802 2 17.7V7.3C2 5.61984 2 4.77976 2.32698 4.13803C2.6146 3.57354 3.07354 3.1146 3.63803 2.82698C4.27976 2.5 5.11984 2.5 6.8 2.5H17.2C18.8802 2.5 19.7202 2.5 20.362 2.82698C20.9265 3.1146 21.3854 3.57354 21.673 4.13803C22 4.77976 22 5.61984 22 7.3V17.7C22 19.3802 22 20.2202 21.673 20.862C21.3854 21.4265 20.9265 21.8854 20.362 22.173C20.2487 22.2308 20.1292 22.2783 20 22.3174M4.00002 22.3174C4.00035 21.5081 4.00521 21.0799 4.07686 20.7196C4.39249 19.1329 5.63288 17.8925 7.21964 17.5769C7.60603 17.5 8.07069 17.5 9 17.5H15C15.9293 17.5 16.394 17.5 16.7804 17.5769C18.3671 17.8925 19.6075 19.1329 19.9231 20.7196C19.9948 21.0799 19.9996 21.5081 20 22.3174M16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10Z"
                                    stroke="#E66927"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                                  Already a Member?
                                </h5>
                              </div>
                            </div>
                            <form id="loginForm" action="#" className="mt-6">
                              <div className="flex flex-col gap-2 mb-4">
                                <div className="flex items-center justify-between">
                                  <label
                                    htmlFor="username"
                                    className="text-sm text-neutral-900 font-medium tracking-[0.2px] "
                                  >
                                    Username
                                  </label>
                                </div>
                                <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                                  <Field
                                    className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal"
                                    type="text"
                                    name="username"
                                    placeholder="Enter username..."
                                  />
                                </div>
                                <ErrorMessage
                                  name="username"
                                  component="div"
                                  className="text-red-600 text-sm"
                                />
                              </div>
                              <div className="flex flex-col gap-2 mb-6">
                                <div className="flex items-center justify-between">
                                  <label
                                    htmlFor="password"
                                    className="text-sm text-neutral-900 font-medium tracking-[0.2px] "
                                  >
                                    Password
                                  </label>
                                  <a className="text-sm text-[#0355A5] font-semibold tracking-[0.2px] cursor-pointer">
                                    forgotten your password?
                                  </a>
                                </div>
                                <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                                  <input
                                    className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal"
                                    type="password"
                                    name="password"
                                    id="passwordPopover"
                                    placeholder="Enter password..."
                                  />
                                  <button
                                    type="button"
                                    id="toggleShowHidePopover"
                                    className="w-[20px] h-[20px]"
                                  >
                                    <img
                                      id="eyeIcon"
                                      src="../assets/images/v1/single/eye-hover-outline.svg"
                                      alt="show-password"
                                    />
                                  </button>
                                </div>
                              </div>
                              <div className="flex items-center gap-2 mb-6">
                                <input
                                  type="checkbox"
                                  name="remembers"
                                  id="remember"
                                  className="w-6 h-6 accent-primary-main border border-[#CBD5E1]"
                                />
                                <label
                                  htmlFor="vehicle1"
                                  className="text-sm font-medium"
                                >
                                  Remember Me
                                </label>
                                <br />
                              </div>
                              <div className="flex flex-col gap-4 justify-center items-center">
                                <button
                                  type="submit"
                                  className="py-[11.5px] flex justify-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full tracking-[0.2px]"
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting ? <Spinner /> : "LOGIN"}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <span className="w-[1.5px] h-[13px] bg-[#334155]" />
                        <a
                          href="#"
                          className="text-red-500 font-normal text-[16px] leading-[24px]"
                        >
                          {isAuthPage ? (
                            <span onClick={handleLogoutHeader}>Logout</span>
                          ) : (
                            <span onClick={showRegistretionForm}>Register</span>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-[24px] pl-[24px]">
                    <div className="w-fit p-[8px] relative">
                      <p className="text-center py-[2px] px-[4px] min-w-[19px] rounded-full bg-[#FF4747] text-[10px] font-semibold text-white absolute top-[-4px] right-[-4px]">
                        8
                      </p>
                      <button className="block">
                        <img
                          src="../assets/images/v1/icons/shopping.svg"
                          className="w-[24px] h-[24px]"
                          alt="shopping"
                        />
                      </button>
                    </div>
                    <div className="w-fit p-[8px] relative">
                      <p className="text-center py-[2px] px-[4px] rounded-full bg-[#FF4747] text-[10px] font-semibold text-white absolute top-[-4px] right-[-4px]">
                        10
                      </p>
                      <button className="block">
                        <img
                          src="../assets/images/v1/icons/wishlist.svg"
                          className="w-[24px] h-[24px]"
                          alt="wishlist"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-fit tablet:hidden flex items-center gap-[12px] ml-[38px] pr-[24px] border-r border-neutral-200">
                  <div className="w-fit p-[8px]">
                    <img
                      src="../assets/images/v1/icons/user.svg"
                      className="w-[24px] h-[24px]"
                      alt="user"
                    />
                  </div>
                  <div className="grid">
                    <h3 className="font-normal text-[14px] leading-[21px] text-neutral-500">
                      Hello, {userName}!
                    </h3>
                    <div className="flex items-center gap-2">
                      <div className="relative popover">
                        {/* <a
                            href="#"
                            onClick={toggleModal}
                            className={`${
                              isOpen ? "text-gray-500" : "text-red-500"
                            } popover-trigger  font-normal text-[16px] leading-[24px]`}
                          > */}
                        {/* {isAuthPage ? "Login" : userNameHeader} */}
                        {/* </a> */}
                        {/* {isAuthPage ? "Login" : userNameHeader} */}
                        {isAuthPage ? (
                          <a
                            href=""
                            onClick={redirectUserDashboard}
                            className={`${
                              isSupplierDashboard
                                ? "text-gray-500"
                                : "text-red-500"
                            } `}
                          >
                            Dashboard
                          </a>
                        ) : (
                          // const isSupplierDashboard = location.pathname.includes('/supplier-dashboard');
                          <a
                            onClick={toggleModal}
                            className={`${
                              isOpen ? "text-gray-500" : "text-red-500"
                            } popover-trigger  font-normal text-[16px] leading-[24px]`}
                          >
                            Login
                          </a>
                        )}

                        {/* Modal just below the Login link */}
                        {isOpen && (
                          <div
                            className={`${
                              isRegisterModalOpen
                                ? "hidden"
                                : " absolute right-2 z-10 top-[100%] min-w-[400px] mt-2 phone:hidden p-6 shadow-sm bg-white border border-neutral-200 rounded-lg"
                            }`}
                          >
                            <div className="flex flex-col gap-3 phone:gap-[16px]">
                              <div className="flex items-center gap-2">
                                <svg
                                  width={24}
                                  height={25}
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g opacity="0.12">
                                    <path
                                      d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
                                      fill="#E66927"
                                    />
                                    <path
                                      d="M16 17.5H8C5.79086 17.5 4 19.2909 4 21.5C4 22.0523 4.44772 22.5 5 22.5H19C19.5523 22.5 20 22.0523 20 21.5C20 19.2909 18.2091 17.5 16 17.5Z"
                                      fill="#E66927"
                                    />
                                  </g>
                                  <path
                                    d="M4.00002 22.3174C4.6026 22.5 5.41649 22.5 6.8 22.5H17.2C18.5835 22.5 19.3974 22.5 20 22.3174M4.00002 22.3174C3.87082 22.2783 3.75133 22.2308 3.63803 22.173C3.07354 21.8854 2.6146 21.4265 2.32698 20.862C2 20.2202 2 19.3802 2 17.7V7.3C2 5.61984 2 4.77976 2.32698 4.13803C2.6146 3.57354 3.07354 3.1146 3.63803 2.82698C4.27976 2.5 5.11984 2.5 6.8 2.5H17.2C18.8802 2.5 19.7202 2.5 20.362 2.82698C20.9265 3.1146 21.3854 3.57354 21.673 4.13803C22 4.77976 22 5.61984 22 7.3V17.7C22 19.3802 22 20.2202 21.673 20.862C21.3854 21.4265 20.9265 21.8854 20.362 22.173C20.2487 22.2308 20.1292 22.2783 20 22.3174M4.00002 22.3174C4.00035 21.5081 4.00521 21.0799 4.07686 20.7196C4.39249 19.1329 5.63288 17.8925 7.21964 17.5769C7.60603 17.5 8.07069 17.5 9 17.5H15C15.9293 17.5 16.394 17.5 16.7804 17.5769C18.3671 17.8925 19.6075 19.1329 19.9231 20.7196C19.9948 21.0799 19.9996 21.5081 20 22.3174M16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10Z"
                                    stroke="#E66927"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                                  Already a Member?
                                </h5>
                              </div>
                            </div>
                            <Formik
                              initialValues={{
                                username: "",
                                password: "",
                                remember: false,
                              }}
                              validationSchema={validationSchema1}
                              onSubmit={handleSubmit}
                            >
                              {({ isSubmitting, errors }) => (
                                <Form
                                  id="loginForm"
                                  action="#"
                                  className="mt-6"
                                >
                                  {/* Username Field */}
                                  <div className="flex flex-col gap-2 mb-4">
                                    <div className="flex items-center justify-between">
                                      <label
                                        htmlFor="username"
                                        className="text-sm text-neutral-900 font-medium tracking-[0.2px] "
                                      >
                                        Username
                                      </label>
                                    </div>
                                    <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                                      <Field
                                        className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal"
                                        type="text"
                                        name="username"
                                        placeholder="Enter username..."
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="username"
                                      component="div"
                                      className="text-red-600 text-sm"
                                    />
                                  </div>

                                  {/* Password Field */}
                                  <div className="flex flex-col gap-2 mb-6">
                                    <div className="flex items-center justify-between">
                                      <label
                                        htmlFor="password"
                                        className="text-sm text-neutral-900 font-medium tracking-[0.2px]"
                                      >
                                        Password
                                      </label>
                                      <p
                                        className="text-sm text-[#0355A5] font-semibold tracking-[0.2px] cursor-pointer"
                                        onClick={toggleForgotPassword}
                                      >
                                        forgotten your password?
                                      </p>
                                    </div>
                                    <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                                      <Field
                                        className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal"
                                        type={
                                          passEye === eyeOpen
                                            ? "password"
                                            : "text"
                                        }
                                        name="password"
                                        placeholder="Enter password..."
                                      />
                                      <button
                                        type="button"
                                        id="toggleShowHidePopover"
                                        className="w-[20px] h-[20px]"
                                        onClick={togglePasswordVisibility}
                                      >
                                        <img
                                          id="eyeIcon"
                                          src={passEye}
                                          alt="show-password"
                                        />
                                      </button>
                                    </div>
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="text-red-600 text-sm"
                                    />
                                  </div>

                                  {/* Remember Me Checkbox */}
                                  <div className="flex items-center gap-2 mb-6">
                                    <Field
                                      type="checkbox"
                                      name="remember"
                                      id="remember"
                                      className="w-6 h-6 accent-primary-main border border-[#CBD5E1]"
                                    />
                                    <label
                                      htmlFor="remember"
                                      className="text-sm font-medium"
                                    >
                                      Remember Me
                                    </label>
                                  </div>

                                  {/* Error Message */}
                                  {errors.general && (
                                    <div className="text-red-600 text-sm mb-4">
                                      {errors.general}
                                    </div>
                                  )}

                                  {/* Submit Button */}
                                  <div className="flex flex-col gap-4 justify-center items-center">
                                    <button
                                      type="submit"
                                      className="py-[11.5px] flex justify-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full tracking-[0.2px]"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting ? <Spinner /> : "LOGIN"}
                                    </button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        )}

                        <div className="popover-menu hidden absolute right-2 top-[100%] min-w-[400px] mt-2 phone:hidden p-6 shadow-sm bg-white border border-neutral-200 rounded-lg">
                          <div className="flex flex-col gap-3 phone:gap-[16px]">
                            <div className="flex items-center gap-2">
                              <svg
                                width={24}
                                height={25}
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.12">
                                  <path
                                    d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
                                    fill="#E66927"
                                  />
                                  <path
                                    d="M16 17.5H8C5.79086 17.5 4 19.2909 4 21.5C4 22.0523 4.44772 22.5 5 22.5H19C19.5523 22.5 20 22.0523 20 21.5C20 19.2909 18.2091 17.5 16 17.5Z"
                                    fill="#E66927"
                                  />
                                </g>
                                <path
                                  d="M4.00002 22.3174C4.6026 22.5 5.41649 22.5 6.8 22.5H17.2C18.5835 22.5 19.3974 22.5 20 22.3174M4.00002 22.3174C3.87082 22.2783 3.75133 22.2308 3.63803 22.173C3.07354 21.8854 2.6146 21.4265 2.32698 20.862C2 20.2202 2 19.3802 2 17.7V7.3C2 5.61984 2 4.77976 2.32698 4.13803C2.6146 3.57354 3.07354 3.1146 3.63803 2.82698C4.27976 2.5 5.11984 2.5 6.8 2.5H17.2C18.8802 2.5 19.7202 2.5 20.362 2.82698C20.9265 3.1146 21.3854 3.57354 21.673 4.13803C22 4.77976 22 5.61984 22 7.3V17.7C22 19.3802 22 20.2202 21.673 20.862C21.3854 21.4265 20.9265 21.8854 20.362 22.173C20.2487 22.2308 20.1292 22.2783 20 22.3174M4.00002 22.3174C4.00035 21.5081 4.00521 21.0799 4.07686 20.7196C4.39249 19.1329 5.63288 17.8925 7.21964 17.5769C7.60603 17.5 8.07069 17.5 9 17.5H15C15.9293 17.5 16.394 17.5 16.7804 17.5769C18.3671 17.8925 19.6075 19.1329 19.9231 20.7196C19.9948 21.0799 19.9996 21.5081 20 22.3174M16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10Z"
                                  stroke="#E66927"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                                Already a Member?
                              </h5>
                            </div>
                          </div>
                          <form
                            id="loginFormPopover"
                            action="#"
                            className="mt-6"
                          >
                            <div className="flex flex-col gap-2 mb-4">
                              <div className="flex items-center justify-between">
                                <label
                                  htmlFor="username"
                                  className="text-sm text-neutral-900 font-medium tracking-[0.2px] "
                                >
                                  Username
                                </label>
                              </div>
                              <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                                <Field
                                  className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal"
                                  type="text"
                                  name="username"
                                  placeholder="enter username..."
                                />
                              </div>
                            </div>
                            <div className="flex flex-col gap-2 mb-6">
                              <div className="flex items-center justify-between">
                                <label
                                  htmlFor="password"
                                  className="text-sm text-neutral-900 font-medium tracking-[0.2px] "
                                >
                                  Password
                                </label>
                                <a className="text-sm text-[#0355A5] font-semibold tracking-[0.2px]">
                                  forgotten your password?
                                </a>
                              </div>
                              <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                                <input
                                  className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal"
                                  type="password"
                                  name="password"
                                  id="passwordPopover"
                                  placeholder="enter password..."
                                />
                                <button
                                  type="button"
                                  id="toggleShowHidePopover"
                                  className="w-[20px] h-[20px]"
                                >
                                  <img
                                    id="eyeIcon"
                                    src="../assets/images/v1/single/eye-hover-outline.svg"
                                    alt="show-password"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="flex items-center gap-2 mb-6">
                              <input
                                type="checkbox"
                                name="remembers"
                                id="remember"
                                className="w-6 h-6 accent-primary-main border border-[#CBD5E1]"
                              />
                              <label
                                htmlFor="vehicle1"
                                className="text-sm font-medium"
                              >
                                Remember Me
                              </label>
                            </div>
                            <div className="flex flex-col gap-4 justify-center items-center">
                              <button
                                type="submit"
                                className="py-[11.5px] flex justify-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full tracking-[0.2px]"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? <Spinner /> : "LOGIN"}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <span className="w-[1.5px] h-[13px] bg-[#334155]" />
                      <div className="relative">
                        <a
                          className={`${
                            isRegisterModalOpen
                              ? "text-gray-500"
                              : "text-red-500"
                          } popover-trigger  font-normal text-[16px] leading-[24px] cursor-pointer`}
                          // onClick={toggleRegisterModal}
                        >
                          {isAuthPage ? (
                            <span onClick={handleLogoutHeader}>Logout</span>
                          ) : (
                            <span onClick={showRegistretionForm}>Register</span>
                          )}
                        </a>
                        {isRegisterModalOpen && (
                          <div
                            className={`${
                              isOpen
                                ? "hidden"
                                : "absolute right-4 bg-white z-10 border px-3 py-3"
                            }`}
                          >
                            <Formik
                              initialValues={{
                                username: "",
                                gender: "",
                                firstName: "",
                                lastName: "",
                                email: "",
                                password: "",
                                confirmPassword: "",
                                agree: false,
                              }}
                              validationSchema={validationSchema}
                              onSubmit={handleSubmit}
                            >
                              {({ isSubmitting, errors }) =>
                                isSubmitting || isRegisterSuccess ? (
                                  <div className="flex justify-center items-center h-screen">
                                    <Spinner />
                                  </div>
                                ) : (
                                  <>
                                    <div className=" flex flex-col gap-3 phone:gap-4">
                                      <div className="flex items-center gap-2">
                                        <svg
                                          width={24}
                                          height={25}
                                          viewBox="0 0 24 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            opacity="0.12"
                                            d="M3 18.8257C3 18.3365 3 18.0919 3.05526 17.8618C3.10425 17.6577 3.18506 17.4626 3.29472 17.2837C3.4184 17.0818 3.59136 16.9089 3.93726 16.563L16.5 4.00023C17.3285 3.1718 18.6716 3.1718 19.5 4.00023C20.3285 4.82865 20.3285 6.1718 19.5 7.00023L6.93729 19.563C6.59138 19.9089 6.41843 20.0818 6.2166 20.2055C6.03765 20.3152 5.84256 20.396 5.63849 20.445C5.40832 20.5002 5.16372 20.5002 4.67454 20.5002H3V18.8257Z"
                                            fill="#E66927"
                                          />
                                          <path
                                            d="M12 20.5002H21M3 20.5002H4.67454C5.16372 20.5002 5.40832 20.5002 5.63849 20.445C5.84256 20.396 6.03765 20.3152 6.2166 20.2055C6.41843 20.0818 6.59138 19.9089 6.93729 19.563L19.5 7.00023C20.3285 6.1718 20.3285 4.82865 19.5 4.00023C18.6716 3.1718 17.3285 3.1718 16.5 4.00023L3.93726 16.563C3.59136 16.9089 3.4184 17.0818 3.29472 17.2837C3.18506 17.4626 3.10425 17.6577 3.05526 17.8618C3 18.0919 3 18.3365 3 18.8257V20.5002Z"
                                            stroke="#E66927"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                                          Register Free
                                        </h5>
                                      </div>
                                    </div>
                                    <Form id="registerForm" className="mt-2">
                                      {/* Username */}
                                      <div className="flex flex-col gap-2 mb-1">
                                        <label
                                          htmlFor="username"
                                          className="text-sm font-medium flex items-center gap-1"
                                        >
                                          Choose Your Username{" "}
                                          <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-1 inline-block"></i>
                                        </label>
                                        <Field
                                          type="text"
                                          name="username"
                                          placeholder="Enter username..."
                                          className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                                        />
                                        <ErrorMessage
                                          name="username"
                                          component="div"
                                          className="text-red-600 text-sm"
                                        />
                                      </div>

                                      {/* Gender */}
                                      <div className="flex items-center gap-1 mb-1">
                                        Salutation{" "}
                                        <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                                      </div>
                                      <div className="flex items-center gap-6 mb-1">
                                        <div
                                          className="flex items-center gap-2 border border-neutral-200 w-[17rem] py-3 px-4 rounded cursor-pointer"
                                          onClick={() =>
                                            document
                                              .getElementById("genderMale")
                                              .click()
                                          }
                                        >
                                          <Field
                                            id="genderMale"
                                            type="radio"
                                            name="gender"
                                            value="male"
                                            className="accent-primary-main cursor-pointer"
                                          />
                                          <label htmlFor="genderMale">
                                            Mr.
                                          </label>
                                        </div>

                                        <div
                                          className="flex items-center gap-2 border border-neutral-200 w-[17rem] py-3 px-4 rounded cursor-pointer"
                                          onClick={() =>
                                            document
                                              .getElementById("genderFemale")
                                              .click()
                                          }
                                        >
                                          <Field
                                            id="genderFemale"
                                            type="radio"
                                            name="gender"
                                            value="female"
                                            className="accent-primary-main cursor-pointer"
                                          />
                                          <label htmlFor="genderFemale">
                                            Ms.
                                          </label>
                                        </div>
                                      </div>

                                      <ErrorMessage
                                        name="gender"
                                        component="div"
                                        className="text-red-600 text-sm mt-[-0.5rem] mb-1"
                                      />

                                      {/* Full Name */}
                                      <div className="w-full grid grid-cols-2 gap-7">
                                        <div className="flex flex-col gap-2 mb-1">
                                          <label
                                            htmlFor="firstName"
                                            className="text-sm font-medium flex items-center gap-1"
                                          >
                                            First Name{" "}
                                            <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                                          </label>
                                          <Field
                                            type="text"
                                            name="firstName"
                                            placeholder="Enter first name..."
                                            className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                                          />
                                          <ErrorMessage
                                            name="firstName"
                                            component="div"
                                            className="text-red-600 text-sm"
                                          />
                                        </div>
                                        <div className="flex flex-col gap-2 mb-1">
                                          <label
                                            htmlFor="lastName"
                                            className="text-sm font-medium flex items-center gap-1"
                                          >
                                            Last Name{" "}
                                            <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                                          </label>
                                          <Field
                                            type="text"
                                            name="lastName"
                                            placeholder="Enter Last name..."
                                            className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                                          />
                                          <ErrorMessage
                                            name="lastName"
                                            component="div"
                                            className="text-red-600 text-sm"
                                          />
                                        </div>
                                      </div>

                                      {/* Email */}
                                      <div className="flex flex-col gap-2 mb-1">
                                        <label
                                          htmlFor="email"
                                          className="text-sm font-medium flex items-center gap-1"
                                        >
                                          Email{" "}
                                          <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                                        </label>
                                        <Field
                                          type="email"
                                          name="email"
                                          placeholder="Enter email address..."
                                          className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                                        />
                                        <ErrorMessage
                                          name="email"
                                          component="div"
                                          className="text-red-600 text-sm"
                                        />
                                      </div>
                                      <div className="w-full grid grid-cols-2 gap-7">
                                        {/* Password */}
                                        <div className="flex flex-col gap-2 mb-1">
                                          <label
                                            htmlFor="password"
                                            className="text-sm font-medium flex items-center gap-1"
                                          >
                                            Choose Your Password{" "}
                                            <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                                          </label>
                                          <div className="relative">
                                            <Field
                                              type={
                                                passwordEye === eyeOpen
                                                  ? "password"
                                                  : "text"
                                              }
                                              name="password"
                                              placeholder="Enter password..."
                                              className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                                            />
                                            <img
                                              src={passwordEye}
                                              alt=""
                                              className="absolute top-3 right-3"
                                              onClick={() =>
                                                passwordEye === eyeOpen
                                                  ? setPasswordEye(eyeClose)
                                                  : setPasswordEye(eyeOpen)
                                              }
                                            />
                                          </div>

                                          <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="text-red-600 text-sm"
                                          />
                                        </div>

                                        {/* Confirm Password */}
                                        <div className="flex flex-col gap-2 mb-1">
                                          <label
                                            htmlFor="confirmPassword"
                                            className="text-sm font-medium flex items-center gap-1"
                                          >
                                            Confirm Password{" "}
                                            <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                                          </label>
                                          <div className="relative">
                                            <Field
                                              type={
                                                cPasswordEye === eyeOpen
                                                  ? "password"
                                                  : "text"
                                              }
                                              name="confirmPassword"
                                              placeholder="Re-enter password..."
                                              className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                                            />
                                            <img
                                              src={cPasswordEye}
                                              alt=""
                                              className="absolute top-3 right-3"
                                              onClick={() =>
                                                cPasswordEye === eyeOpen
                                                  ? setCPasswordEye(eyeClose)
                                                  : setCPasswordEye(eyeOpen)
                                              }
                                            />
                                          </div>
                                          <ErrorMessage
                                            name="confirmPassword"
                                            component="div"
                                            className="text-red-600 text-sm"
                                          />
                                        </div>
                                      </div>

                                      {/* Terms Agreement */}
                                      <div className="flex items-center gap-2 mb-5">
                                        <Field
                                          id="termsAgreement"
                                          type="checkbox"
                                          name="agree"
                                          className="w- accent-primary-main border border-[#CBD5E1] cursor-pointer"
                                        />
                                        <label
                                          htmlFor="agree"
                                          className="text-sm font-medium"
                                          onClick={() =>
                                            document
                                              .getElementById("termsAgreement")
                                              .click()
                                          }
                                        >
                                          I have read and agree to the Wholesale
                                          Deals Terms
                                        </label>
                                      </div>
                                      <ErrorMessage
                                        name="agree"
                                        component="div"
                                        className="text-red-600 text-sm"
                                      />
                                      {/* Error Message */}
                                      {errors.general && (
                                        <div className="text-red-600 text-sm">
                                          {errors.general}
                                        </div>
                                      )}

                                      {/* Submit Button */}
                                      <div className="flex flex-col gap-4 justify-center items-center">
                                        <button
                                          type="submit"
                                          className="py-3 flex justify-center items-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full"
                                          disabled={isSubmitting}
                                        >
                                          {isSubmitting ? (
                                            <Spinner />
                                          ) : (
                                            "SIGN UP"
                                          )}
                                        </button>
                                      </div>
                                    </Form>
                                  </>
                                )
                              }
                            </Formik>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tablet:hidden flex items-center gap-[24px] pl-[24px]">
                  <div className="w-fit p-[8px] relative">
                    <p className="text-center py-[2px] px-[4px] min-w-[19px] rounded-full bg-[#FF4747] text-[10px] font-semibold text-white absolute top-[-4px] right-[-4px]">
                      8
                    </p>
                    <button className="block">
                      <img
                        src="../assets/images/v1/icons/shopping.svg"
                        className="w-[24px] h-[24px]"
                        alt="shopping"
                      />
                    </button>
                  </div>
                  <div className="w-fit p-[8px] relative">
                    <p className="text-center py-[2px] px-[4px] rounded-full bg-[#FF4747] text-[10px] font-semibold text-white absolute top-[-4px] right-[-4px]">
                      10
                    </p>
                    <button className="block">
                      <img
                        src="../assets/images/v1/icons/wishlist.svg"
                        className="w-[24px] h-[24px]"
                        alt="wishlist"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden w-full tablet:flex items-center relative px-[56px]">
                <div className="relative select-dropdown-container">
                  <button className="relative py-[8px] flex items-center gap-1 px-[16px] rounded-l-[8px] border border-r-[0px] border-neutral-300 text-[14px] leading-[21px] text-neutral-700">
                    Product
                    <img
                      src="../assets/images/v1/icons/chevron-down.svg"
                      width="16px"
                      height="16px"
                      alt="icons"
                    />
                  </button>
                  <div className="hidden bg-white shadow w-[152px] flex flex-col gap-[4px] absolute z-[1000] mt-[6px] left-[0px] p-[4px] bg-white select-dropdown">
                    <button className="px-4 transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left hover:bg-[#FAF1DC]">
                      Deals
                    </button>
                    <button className="px-4 transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left hover:bg-[#FAF1DC]">
                      Suppliers
                    </button>
                    <button className="px-4 transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left hover:bg-[#FAF1DC]">
                      Wholesalers
                    </button>
                    <button className="px-4 transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left hover:bg-[#FAF1DC]">
                      Distributors
                    </button>
                    <button className="px-4 transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left hover:bg-[#FAF1DC]">
                      Liquidators
                    </button>
                    <button className="px-4 transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left hover:bg-[#FAF1DC]">
                      Drop shippers
                    </button>
                  </div>
                </div>
                <input
                  type="search"
                  className="w-full py-[8px] px-[16px] rounded-r-[8px] border border-neutral-300 text-[14px] leading-[21px]"
                  placeholder="What are you looking for ?"
                  name="search"
                  id="search"
                />
                <img
                  data-name="search-icon"
                  src="../assets/images/v1/icons/search.svg"
                  className="w-[18px] h-[18px] absolute top-[11px] right-[72px]"
                  alt="search"
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default LoginHeader;
