// src/components/LanguageDropdown.js
import React, { useState } from 'react';
import chevronDown from "../../../assets/images/v1/icons/chevron-down.svg"; // Update the path as needed
import chevronUp from "../../../assets/icons/chevron-up.png";// Ensure you have this image for rotation

const Gbp = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className="relative select-dropdown-container">
      <button
        className="open-language flex px-2 items-center gap-[6px] py-[3px] rounded-[6px] transition-all duration-300"
        onClick={toggleDropdown}
      >
        <p className="font-normal text-[14px] leading-[21px] text-neutral-800">
          GBP
        </p>
        <img
          src={isOpen ? chevronUp : chevronDown}
          className={`tablet:w-[16px] tablet:h-[16px] transition-transform duration-300 ${isOpen ? 'rotate-120' : ''}  text-black`}
          width="16px"
          height="16px"
          alt="Chevron"
        />
      </button>
      <div className={`w-[80px] flex flex-col absolute text-left mt-[7px] right-0 p-[4px] bg-white ${isOpen ? 'block' : 'hidden'} language-modal select-dropdown`}>
        <button className="transition-all text-left pl-2 duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 hover:bg-[#FAF1DC]">
          IDR
        </button>
        <button className="transition-all text-left pl-2 duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 hover:bg-[#FAF1DC]">
          MYR
        </button>
        <button className="transition-all text-left pl-2 duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 hover:bg-[#FAF1DC]">
          SGD
        </button>
      </div>
    </div>
  );
};

export default Gbp;
