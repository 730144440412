import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GETDEALS } from "../api";
import logout from "../../shared/logout";
export const getDeals = createAsyncThunk(
  "/getDeals",

  async (data, { rejectWithValue }) => {
    try {
      console.log("Fetching deals with data: ", data); // Debugging line
      
      if (data.dealId === "") {
        console.log("Fetching all deals..."); // Debugging line
        const response = await axios.get(`https://gazaniainfotech.ngrok.dev/api/user/deals`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        return response.data; // Ensure this is the expected format
      } else if (data.dealId.trim() !== "") {
        console.log(`Fetching deals for dealId: ${data.dealId}`); // Debugging line
        const response = await axios.get(`${GETDEALS}?page=${data.dealId}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        
        return response.data; // Ensure this is the expected format
      }
    } catch (error) {
      console.error("Error fetching deals: ", error); // Debugging line
      return rejectWithValue(
        error.response?.data || "Something went wrong while fetching the deal profile."
      );
    }
  }
);



      



const initialState = {
  data: {},
  isLoading: true,
  currentPage: 1,
  itemsPerPage: 14,
  error: null,
};

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeals.fulfilled, (state, action) => {
        console.log('API call succeeded:', action.payload);
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getDeals.rejected, (state) => {
        console.log('API call failed:');
        state.isLoading = false;
      })
     
      
      
  },
});

export const { setPage, setError } = dealsSlice.actions;
export default dealsSlice.reducer;