import React, { useEffect } from "react";
import Card from "./Card";
import LoadingCard from "./LoadingCard";
import { formatDate, getLowestPriceObject } from "../../constant";

const CardContainer = ({ deals, isLoading }) => {
  useEffect(() => {
    // console.log("deals card container", deals);
  }, [deals]);

  return (
    <div className="container mx-auto py-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {isLoading
          ? [...Array(15)].map((_, index) => <LoadingCard />)
          : deals &&
            deals.length &&
            deals.map((deal, index) => (
              <Card
                key={index}
                image={
                  deal.image ||
                  "../../../../../assets/images/v1/homepage/shoes.png"
                }
                inVat={getLowestPriceObject(deal.prices).fldIncVAT}
                title={deal.fldDealTitle || "title na"}
                price={getLowestPriceObject(deal.prices).fldPrice || "NA"}
                markup={deal.fldDealMarkup || "markup na"}
                rrp={deal.fldDealRRP || "deal.rrp"}
                grossProfit={
                  deal.fldDealProfitMinOrderUnits === 0
                    ? "0"
                    : deal.fldDealProfitMinOrderUnits
                }
                featuredDate={
                  formatDate(deal.fldDealCreationDate) || "deal.featuredOn"
                }
                units={deal.fldDealMinOrderUnits || 50}
                comparison={
                  deal.comparison || [
                    {
                      name: "Amazon",
                      logo: "../../../../../assets/images/v1/homepage/amazon.svg",
                      price: "12.35",
                    },
                    {
                      name: "Ebay",
                      logo: "../../../../../assets/images/v1/homepage/ebay.svg",
                      price: "12.35",
                    },
                  ]
                }
              />
            ))}
      </div>
    </div>
  );
};

export default CardContainer;
