import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Filter from "./components/Filter/Filter";
import Breadcrumb from "./components/Breadcrumb/Breadcrumb";
import SearchFilterComponent from "./components/SearchFilterComponent/SearchFilterComponent";
import OfferHeaderComponent from "./components/OfferHeaderComponent/OfferHeaderComponent";
import CardContainer from "./components/Card/CardContainer";
import Pagination from "./components/Pagination/Pagination";
import TopOffers from "./components/TopOffers/TopOffers";
import FilterCtaWrapper from "./components/Filter/FilterCtaWrapper";
import { getCommonDeals } from "../../../store/slices/commonDealsSlice";
import Cookies from "js-cookie";
import { getScrollerDeals } from "../../../store/slices/scrollDealsSlice";

const Deals = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.data);
  // Get the current page, search query, and search mode from query params
  const { id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const initialSearchTerm = queryParams.get("search") || "";
  const initialSearchMode = queryParams.get("searchMode") || "any";
  const initialSortBy = queryParams.get("sortBy") || "";
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchMode, setSearchMode] = useState(initialSearchMode);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const { deals, isLoading, totalDeals } = useSelector(
    (state) => state.common_deals.data
  );

  const itemsPerPage = 10;

  // Update the query string in the URL when the page, search term, or search mode changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateUrl(page, searchTerm, searchMode, sortBy);
  };

  const handleSearch = (newSearchTerm, newSearchMode, newSortBy) => {
    setSearchTerm(newSearchTerm);
    setSearchMode(newSearchMode); // Update the search mode in state
    setSortBy(newSortBy);
    setCurrentPage(1); // Reset to page 1 when searching
    updateUrl(1, newSearchTerm, newSearchMode, newSortBy);
  };

  const updateUrl = (page, search, mode, sort) => {
    const searchParams = new URLSearchParams();
    if (page) searchParams.set("page", page);
    if (search) searchParams.set("search", search);
    if (mode) searchParams.set("searchMode", mode);
    if (sort) searchParams.set("sortBy", sort);
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    console.log("id", id);
  }, [id]);

  useEffect(() => {
    dispatch(
      getCommonDeals({
        dealId: `${currentPage}`,
        search: searchTerm,
        searchMode,
        sortBy,
      })
    );

    dispatch(getScrollerDeals());
  }, [dispatch, currentPage, searchTerm, searchMode, sortBy]);

  const breadcrumbLinks = [
    { label: "Wholesale Deals", href: "#" },
    { label: "Deals", href: "#" },
    {
      label: "Sports, hobbies & leisure - Category",
      href: "#",
      isActive: true,
    },
  ];

  return (
    <div className="w-full max-w-[1440px] mx-auto px-[56px] phone:px-[24px] flex items-start">
      <div className="w-[314px] min-w-[314px] py-[24px]  desktop:grid gap-[24px] phone:hidden tablet:hidden">
        <FilterCtaWrapper />
      </div>
      <div className="w-full desktop:pl-[48px] phone:pl-[10px] pt-[24px] phone:pt-[32px] pb-[72px] phone:pb-[32px] tablet:pb-[56px]">
        {/* Breadcrumb */}
        <Breadcrumb categories={categories} id={id} />

        {/* Breadcrumb end */}
        <SearchFilterComponent onSearch={handleSearch} />
        <TopOffers />
        <CardContainer deals={deals} isLoading={isLoading} />
        <Pagination
          totalItems={totalDeals}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Deals;
