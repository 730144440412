import { useAuth, useRedirection } from "./index";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
const useUser = () => {
  const { getUserInfo } = useAuth();
  const { redirectLogin } = useRedirection();
  const location = useLocation(); // Get the current route

  const returnUserId = () => {
    const bypassRoutes = ["/reset-password",'/userlogin',"/deals"];

    const currentPath = location.pathname;
    // Check if the current route should bypass authentication checks
    if (bypassRoutes.some((route) => isRouteMatch(route, currentPath))) {
      return;
    }
    const getUserid = getUserInfo();
    if (!getUserid?.userId) {
      redirectLogin();
    } else {
      return getUserid.userId;
    }
  };

  const getUserToken = () => {
    const token = Cookies.get("wd-team");
    return token && token;
  };

  // Utility function to check if the path matches the route pattern
  const isRouteMatch = (pattern, path) => {
    // Normalize both paths to ensure consistent matching
    const normalizedPattern = pattern.endsWith("/")
      ? pattern.slice(0, -1)
      : pattern;
    const normalizedPath = path.endsWith("/") ? path.slice(0, -1) : path;

    // Check if the normalized path starts with the normalized pattern
    return normalizedPath.startsWith(normalizedPattern);
  };
  return {
    returnUserId,
    getUserToken,
  };
};

export default useUser;
