import React from "react";
import eyeoff from "../../../../../assets/images/v1/deals/eyeoff.svg";
import heart from "../../../../../assets/images/v1/icons/heart-rounded.svg";
const Card = ({
  image,
  title,
  price,
  markup,
  rrp,
  inVat,
  grossProfit,
  featuredDate,
  comparison,
  units,
  topOffer,
}) => {
  return (
    <div className="w-full bg-white border border-neutral-200 rounded-xl overflow-hidden flex flex-col">
      {/* Badge Section */}
      <div className="relative top-0 p-4 pt-0">
        <img
          src="../../../../../assets/images/v1/icons/badge.svg"
          className="w-16 h-16 absolute"
          alt="badge"
        />
        <p className=" text-[12px] leading-[18px] font-semibold text-white text-center absolute z-10 top-0  pt-[6px] left-5">
          %Markup
        </p>
        <h1 className="relative top-6 left-2 text-secondary-main font-bold text-[16px]">
          {/* font-bold absolute z-10 pt-[6px] top-[16px] right-0 text-secondary-main text-[16px] leading-[24px] text-center */}
          {markup}%
        </h1>
      </div>

      {/* Product Image */}
      <div className="w-full">
        <a href="#">
          <img src={image} alt="product" className="w-full h-auto p-4" />
        </a>
      </div>

      {/* Product Info */}
      <div className="p-4">
        <div className="flex items-center justify-between">
          <p className="text-xl font-bold text-secondary-main">
            £{price}{" "}
            <span className="text-sm font-normal text-neutral-500">
              {inVat ? "inc.Vat " : "ex.VAT"}
            </span>
          </p>
          {!topOffer && (
            <div className="flex items-center gap-4">
              <button className="relative">
                <img src={eyeoff} alt="Hide" className="w-6 h-6" />
              </button>
              <button className="relative">
                <img src={heart} alt="Like" className="w-6 h-6" />
              </button>
            </div>
          )}
        </div>

        <p className="text-sm font-normal text-neutral-600 my-2">
          Deal First Featured On: {featuredDate}
        </p>

        <a
          href="#"
          className="font-bold text-md text-neutral-900 hover:text-blue-600 transition-all"
        >
          {title}
        </a>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <p className="text-xs text-neutral-600">RRP</p>
            <h4 className="font-bold text-sm text-secondary-main">${rrp}</h4>
          </div>
          <div>
            <p className="text-xs text-neutral-600">Gross Profit</p>
            <h4 className="font-bold text-sm text-secondary-main">
              ${grossProfit}{" "}
              <span className="text-neutral-600">/ {units || 0} units</span>
            </h4>
          </div>
        </div>

        {/* Comparison Section */}
        <div className="mt-4 bg-neutral-50 rounded-lg p-3">
          <p className="text-xs text-neutral-600 border-b pb-3">
            Compare with other platform
          </p>
          {comparison.map((platform, index) => (
            <div
              className="flex items-center justify-between py-3 border-b"
              key={index}
            >
              <div className="flex items-center gap-2">
                <img
                  src={platform.logo}
                  alt={platform.name}
                  className="w-8 h-8"
                />
                <div>
                  <h4 className="font-bold text-sm text-neutral-900">
                    ${platform.price}
                  </h4>
                  <p className="text-xs text-neutral-500">exc.VAT</p>
                </div>
              </div>
              <button className="text-sm font-semibold text-blue-600">
                Verify
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
