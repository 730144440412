import axios from "axios";
import Cookies from "js-cookie"; // Assuming you're storing the token in cookies
import { VALIDATEUSERTOKEN } from "../api";

export const validateUserToken = async () => {
  try {
    const token = Cookies.get("wd-team");

    if (!token) {
      throw new Error("Token not found");
    }

    const response = await axios.post(VALIDATEUSERTOKEN, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      console.log("Token is valid");
      return response.data;
    } else {
      throw new Error("Token validation failed");
    }
  } catch (error) {
    console.error("Error validating token:", error.message);

    return false;
  }
};

export const checkUserToken = async (userID) => {
  const isValid = await validateUserToken();
  console.log("isValid", isValid);
  if (isValid.success) {
    return true;
  }
  return false;
};
