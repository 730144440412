import React from "react";
import BuyerSellerNav from "./components/BuyerSellerNav";
import TopNav from "./components/TopNav";
import MainNav from "./components/MainNav";
import { useLocation } from "react-router-dom";
import { useRedirection } from "../../../hooks";
const Navigation = () => {
  const location = useLocation();
  const {redirectToLoginPage}=useRedirection()
  const isAuthRoute =
    location.pathname.includes("/userlogin") ||
    location.pathname.includes("/reset-password")
   const ShowRegisterForm =()=>{
    redirectToLoginPage()
   }
  return (
    <>
      {" "}
      <TopNav />
      <BuyerSellerNav />
      {!isAuthRoute && <MainNav ShowRegisterForm ={ShowRegisterForm }/>}
    </>
  );
};

export default Navigation;
